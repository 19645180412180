"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Course = exports.course_LocationToJSON = exports.course_LocationFromJSON = exports.Course_Location = exports.typeToJSON = exports.typeFromJSON = exports.Type = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
var lang_1 = require("./lang");
var photo_1 = require("./photo");
var questionnaire_1 = require("./questionnaire");
exports.protobufPackage = "com.afterschool.hero";
var Type;
(function (Type) {
    Type[Type["COURSE_TYPE_UNKNOWN"] = 0] = "COURSE_TYPE_UNKNOWN";
    Type[Type["ONE_TO_ONE"] = 1] = "ONE_TO_ONE";
    Type[Type["ONE_TO_MANY"] = 2] = "ONE_TO_MANY";
    Type[Type["VIDEO_CLASS"] = 3] = "VIDEO_CLASS";
    Type[Type["AI_CLASS"] = 4] = "AI_CLASS";
    Type[Type["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Type || (exports.Type = Type = {}));
function typeFromJSON(object) {
    switch (object) {
        case 0:
        case "COURSE_TYPE_UNKNOWN":
            return Type.COURSE_TYPE_UNKNOWN;
        case 1:
        case "ONE_TO_ONE":
            return Type.ONE_TO_ONE;
        case 2:
        case "ONE_TO_MANY":
            return Type.ONE_TO_MANY;
        case 3:
        case "VIDEO_CLASS":
            return Type.VIDEO_CLASS;
        case 4:
        case "AI_CLASS":
            return Type.AI_CLASS;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Type.UNRECOGNIZED;
    }
}
exports.typeFromJSON = typeFromJSON;
function typeToJSON(object) {
    switch (object) {
        case Type.COURSE_TYPE_UNKNOWN:
            return "COURSE_TYPE_UNKNOWN";
        case Type.ONE_TO_ONE:
            return "ONE_TO_ONE";
        case Type.ONE_TO_MANY:
            return "ONE_TO_MANY";
        case Type.VIDEO_CLASS:
            return "VIDEO_CLASS";
        case Type.AI_CLASS:
            return "AI_CLASS";
        case Type.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.typeToJSON = typeToJSON;
var Course_Location;
(function (Course_Location) {
    Course_Location[Course_Location["LOCATION_UNKNOWN"] = 1] = "LOCATION_UNKNOWN";
    Course_Location[Course_Location["LOCATION_ONLINE"] = 2] = "LOCATION_ONLINE";
    Course_Location[Course_Location["LOCATION_OFFLINE_SEATTLE"] = 3] = "LOCATION_OFFLINE_SEATTLE";
    Course_Location[Course_Location["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Course_Location || (exports.Course_Location = Course_Location = {}));
function course_LocationFromJSON(object) {
    switch (object) {
        case 1:
        case "LOCATION_UNKNOWN":
            return Course_Location.LOCATION_UNKNOWN;
        case 2:
        case "LOCATION_ONLINE":
            return Course_Location.LOCATION_ONLINE;
        case 3:
        case "LOCATION_OFFLINE_SEATTLE":
            return Course_Location.LOCATION_OFFLINE_SEATTLE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Course_Location.UNRECOGNIZED;
    }
}
exports.course_LocationFromJSON = course_LocationFromJSON;
function course_LocationToJSON(object) {
    switch (object) {
        case Course_Location.LOCATION_UNKNOWN:
            return "LOCATION_UNKNOWN";
        case Course_Location.LOCATION_ONLINE:
            return "LOCATION_ONLINE";
        case Course_Location.LOCATION_OFFLINE_SEATTLE:
            return "LOCATION_OFFLINE_SEATTLE";
        case Course_Location.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.course_LocationToJSON = course_LocationToJSON;
function createBaseCourse() {
    return {
        id: "",
        teacherAdminId: "",
        title: "",
        titleChinese: "",
        description: "",
        descriptionChinese: "",
        cover: undefined,
        language: [],
        downloadUrl: "",
        instruction: "",
        instructionChinese: "",
        galleryPhotos: [],
        questionnaire: undefined,
        rating: 0,
        isDelete: false,
        location: 1,
        type: 0,
        studentAgeRange: "",
    };
}
exports.Course = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.teacherAdminId !== "") {
            writer.uint32(154).string(message.teacherAdminId);
        }
        if (message.title !== "") {
            writer.uint32(18).string(message.title);
        }
        if (message.titleChinese !== "") {
            writer.uint32(130).string(message.titleChinese);
        }
        if (message.description !== "") {
            writer.uint32(26).string(message.description);
        }
        if (message.descriptionChinese !== "") {
            writer.uint32(138).string(message.descriptionChinese);
        }
        if (message.cover !== undefined) {
            photo_1.Photo.encode(message.cover, writer.uint32(34).fork()).ldelim();
        }
        writer.uint32(58).fork();
        for (var _i = 0, _a = message.language; _i < _a.length; _i++) {
            var v = _a[_i];
            writer.int32(v);
        }
        writer.ldelim();
        if (message.downloadUrl !== "") {
            writer.uint32(66).string(message.downloadUrl);
        }
        if (message.instruction !== "") {
            writer.uint32(74).string(message.instruction);
        }
        if (message.instructionChinese !== "") {
            writer.uint32(146).string(message.instructionChinese);
        }
        for (var _b = 0, _c = message.galleryPhotos; _b < _c.length; _b++) {
            var v = _c[_b];
            photo_1.Photo.encode(v, writer.uint32(106).fork()).ldelim();
        }
        if (message.questionnaire !== undefined) {
            questionnaire_1.Questionnaire.encode(message.questionnaire, writer.uint32(98).fork()).ldelim();
        }
        if (message.rating !== 0) {
            writer.uint32(89).double(message.rating);
        }
        if (message.isDelete === true) {
            writer.uint32(80).bool(message.isDelete);
        }
        if (message.location !== 1) {
            writer.uint32(112).int32(message.location);
        }
        if (message.type !== 0) {
            writer.uint32(120).int32(message.type);
        }
        if (message.studentAgeRange !== "") {
            writer.uint32(162).string(message.studentAgeRange);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseCourse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 19:
                    if (tag !== 154) {
                        break;
                    }
                    message.teacherAdminId = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.title = reader.string();
                    continue;
                case 16:
                    if (tag !== 130) {
                        break;
                    }
                    message.titleChinese = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.description = reader.string();
                    continue;
                case 17:
                    if (tag !== 138) {
                        break;
                    }
                    message.descriptionChinese = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.cover = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 7:
                    if (tag === 56) {
                        message.language.push(reader.int32());
                        continue;
                    }
                    if (tag === 58) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.language.push(reader.int32());
                        }
                        continue;
                    }
                    break;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.downloadUrl = reader.string();
                    continue;
                case 9:
                    if (tag !== 74) {
                        break;
                    }
                    message.instruction = reader.string();
                    continue;
                case 18:
                    if (tag !== 146) {
                        break;
                    }
                    message.instructionChinese = reader.string();
                    continue;
                case 13:
                    if (tag !== 106) {
                        break;
                    }
                    message.galleryPhotos.push(photo_1.Photo.decode(reader, reader.uint32()));
                    continue;
                case 12:
                    if (tag !== 98) {
                        break;
                    }
                    message.questionnaire = questionnaire_1.Questionnaire.decode(reader, reader.uint32());
                    continue;
                case 11:
                    if (tag !== 89) {
                        break;
                    }
                    message.rating = reader.double();
                    continue;
                case 10:
                    if (tag !== 80) {
                        break;
                    }
                    message.isDelete = reader.bool();
                    continue;
                case 14:
                    if (tag !== 112) {
                        break;
                    }
                    message.location = reader.int32();
                    continue;
                case 15:
                    if (tag !== 120) {
                        break;
                    }
                    message.type = reader.int32();
                    continue;
                case 20:
                    if (tag !== 162) {
                        break;
                    }
                    message.studentAgeRange = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            teacherAdminId: isSet(object.teacherAdminId) ? String(object.teacherAdminId) : "",
            title: isSet(object.title) ? String(object.title) : "",
            titleChinese: isSet(object.titleChinese) ? String(object.titleChinese) : "",
            description: isSet(object.description) ? String(object.description) : "",
            descriptionChinese: isSet(object.descriptionChinese) ? String(object.descriptionChinese) : "",
            cover: isSet(object.cover) ? photo_1.Photo.fromJSON(object.cover) : undefined,
            language: Array.isArray(object === null || object === void 0 ? void 0 : object.language) ? object.language.map(function (e) { return (0, lang_1.languageFromJSON)(e); }) : [],
            downloadUrl: isSet(object.downloadUrl) ? String(object.downloadUrl) : "",
            instruction: isSet(object.instruction) ? String(object.instruction) : "",
            instructionChinese: isSet(object.instructionChinese) ? String(object.instructionChinese) : "",
            galleryPhotos: Array.isArray(object === null || object === void 0 ? void 0 : object.galleryPhotos)
                ? object.galleryPhotos.map(function (e) { return photo_1.Photo.fromJSON(e); })
                : [],
            questionnaire: isSet(object.questionnaire) ? questionnaire_1.Questionnaire.fromJSON(object.questionnaire) : undefined,
            rating: isSet(object.rating) ? Number(object.rating) : 0,
            isDelete: isSet(object.isDelete) ? Boolean(object.isDelete) : false,
            location: isSet(object.location) ? course_LocationFromJSON(object.location) : 1,
            type: isSet(object.type) ? typeFromJSON(object.type) : 0,
            studentAgeRange: isSet(object.studentAgeRange) ? String(object.studentAgeRange) : "",
        };
    },
    toJSON: function (message) {
        var _a, _b;
        var obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.teacherAdminId !== "") {
            obj.teacherAdminId = message.teacherAdminId;
        }
        if (message.title !== "") {
            obj.title = message.title;
        }
        if (message.titleChinese !== "") {
            obj.titleChinese = message.titleChinese;
        }
        if (message.description !== "") {
            obj.description = message.description;
        }
        if (message.descriptionChinese !== "") {
            obj.descriptionChinese = message.descriptionChinese;
        }
        if (message.cover !== undefined) {
            obj.cover = photo_1.Photo.toJSON(message.cover);
        }
        if ((_a = message.language) === null || _a === void 0 ? void 0 : _a.length) {
            obj.language = message.language.map(function (e) { return (0, lang_1.languageToJSON)(e); });
        }
        if (message.downloadUrl !== "") {
            obj.downloadUrl = message.downloadUrl;
        }
        if (message.instruction !== "") {
            obj.instruction = message.instruction;
        }
        if (message.instructionChinese !== "") {
            obj.instructionChinese = message.instructionChinese;
        }
        if ((_b = message.galleryPhotos) === null || _b === void 0 ? void 0 : _b.length) {
            obj.galleryPhotos = message.galleryPhotos.map(function (e) { return photo_1.Photo.toJSON(e); });
        }
        if (message.questionnaire !== undefined) {
            obj.questionnaire = questionnaire_1.Questionnaire.toJSON(message.questionnaire);
        }
        if (message.rating !== 0) {
            obj.rating = message.rating;
        }
        if (message.isDelete === true) {
            obj.isDelete = message.isDelete;
        }
        if (message.location !== 1) {
            obj.location = course_LocationToJSON(message.location);
        }
        if (message.type !== 0) {
            obj.type = typeToJSON(message.type);
        }
        if (message.studentAgeRange !== "") {
            obj.studentAgeRange = message.studentAgeRange;
        }
        return obj;
    },
    create: function (base) {
        return exports.Course.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        var message = createBaseCourse();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.teacherAdminId = (_b = object.teacherAdminId) !== null && _b !== void 0 ? _b : "";
        message.title = (_c = object.title) !== null && _c !== void 0 ? _c : "";
        message.titleChinese = (_d = object.titleChinese) !== null && _d !== void 0 ? _d : "";
        message.description = (_e = object.description) !== null && _e !== void 0 ? _e : "";
        message.descriptionChinese = (_f = object.descriptionChinese) !== null && _f !== void 0 ? _f : "";
        message.cover = (object.cover !== undefined && object.cover !== null) ? photo_1.Photo.fromPartial(object.cover) : undefined;
        message.language = ((_g = object.language) === null || _g === void 0 ? void 0 : _g.map(function (e) { return e; })) || [];
        message.downloadUrl = (_h = object.downloadUrl) !== null && _h !== void 0 ? _h : "";
        message.instruction = (_j = object.instruction) !== null && _j !== void 0 ? _j : "";
        message.instructionChinese = (_k = object.instructionChinese) !== null && _k !== void 0 ? _k : "";
        message.galleryPhotos = ((_l = object.galleryPhotos) === null || _l === void 0 ? void 0 : _l.map(function (e) { return photo_1.Photo.fromPartial(e); })) || [];
        message.questionnaire = (object.questionnaire !== undefined && object.questionnaire !== null)
            ? questionnaire_1.Questionnaire.fromPartial(object.questionnaire)
            : undefined;
        message.rating = (_m = object.rating) !== null && _m !== void 0 ? _m : 0;
        message.isDelete = (_o = object.isDelete) !== null && _o !== void 0 ? _o : false;
        message.location = (_p = object.location) !== null && _p !== void 0 ? _p : 1;
        message.type = (_q = object.type) !== null && _q !== void 0 ? _q : 0;
        message.studentAgeRange = (_r = object.studentAgeRange) !== null && _r !== void 0 ? _r : "";
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
