"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Interest_CourseAndAgeData = exports.Interest = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
var photo_1 = require("./photo");
exports.protobufPackage = "com.afterschool.hero";
function createBaseInterest() {
    return { id: "", name: "", photo: undefined, courses: [] };
}
exports.Interest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.name !== "") {
            writer.uint32(26).string(message.name);
        }
        if (message.photo !== undefined) {
            photo_1.Photo.encode(message.photo, writer.uint32(34).fork()).ldelim();
        }
        for (var _i = 0, _a = message.courses; _i < _a.length; _i++) {
            var v = _a[_i];
            exports.Interest_CourseAndAgeData.encode(v, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseInterest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.photo = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.courses.push(exports.Interest_CourseAndAgeData.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            name: isSet(object.name) ? String(object.name) : "",
            photo: isSet(object.photo) ? photo_1.Photo.fromJSON(object.photo) : undefined,
            courses: Array.isArray(object === null || object === void 0 ? void 0 : object.courses)
                ? object.courses.map(function (e) { return exports.Interest_CourseAndAgeData.fromJSON(e); })
                : [],
        };
    },
    toJSON: function (message) {
        var _a;
        var obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.name !== "") {
            obj.name = message.name;
        }
        if (message.photo !== undefined) {
            obj.photo = photo_1.Photo.toJSON(message.photo);
        }
        if ((_a = message.courses) === null || _a === void 0 ? void 0 : _a.length) {
            obj.courses = message.courses.map(function (e) { return exports.Interest_CourseAndAgeData.toJSON(e); });
        }
        return obj;
    },
    create: function (base) {
        return exports.Interest.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c;
        var message = createBaseInterest();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.name = (_b = object.name) !== null && _b !== void 0 ? _b : "";
        message.photo = (object.photo !== undefined && object.photo !== null) ? photo_1.Photo.fromPartial(object.photo) : undefined;
        message.courses = ((_c = object.courses) === null || _c === void 0 ? void 0 : _c.map(function (e) { return exports.Interest_CourseAndAgeData.fromPartial(e); })) || [];
        return message;
    },
};
function createBaseInterest_CourseAndAgeData() {
    return { courseId: "", minAge: 0, maxAge: 0 };
}
exports.Interest_CourseAndAgeData = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.courseId !== "") {
            writer.uint32(10).string(message.courseId);
        }
        if (message.minAge !== 0) {
            writer.uint32(16).int32(message.minAge);
        }
        if (message.maxAge !== 0) {
            writer.uint32(24).int32(message.maxAge);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseInterest_CourseAndAgeData();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.courseId = reader.string();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.minAge = reader.int32();
                    continue;
                case 3:
                    if (tag !== 24) {
                        break;
                    }
                    message.maxAge = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            courseId: isSet(object.courseId) ? String(object.courseId) : "",
            minAge: isSet(object.minAge) ? Number(object.minAge) : 0,
            maxAge: isSet(object.maxAge) ? Number(object.maxAge) : 0,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.courseId !== "") {
            obj.courseId = message.courseId;
        }
        if (message.minAge !== 0) {
            obj.minAge = Math.round(message.minAge);
        }
        if (message.maxAge !== 0) {
            obj.maxAge = Math.round(message.maxAge);
        }
        return obj;
    },
    create: function (base) {
        return exports.Interest_CourseAndAgeData.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c;
        var message = createBaseInterest_CourseAndAgeData();
        message.courseId = (_a = object.courseId) !== null && _a !== void 0 ? _a : "";
        message.minAge = (_b = object.minAge) !== null && _b !== void 0 ? _b : 0;
        message.maxAge = (_c = object.maxAge) !== null && _c !== void 0 ? _c : 0;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
