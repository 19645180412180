"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClassTeacherView = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
var Long = require("long");
exports.protobufPackage = "com.afterschool.hero";
function createBaseClassTeacherView() {
    return {
        id: "",
        courseId: "",
        weeklySessionId: "",
        studentClassIds: [],
        unassignedStudentClassIds: [],
        hasStudents: false,
        studentCount: 0,
        availableSpotCount: 0,
        teacherAdminId: "",
        capacityOverride: 0,
        startTimestampMs: 0,
        endTimestampMs: 0,
    };
}
exports.ClassTeacherView = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.courseId !== "") {
            writer.uint32(66).string(message.courseId);
        }
        if (message.weeklySessionId !== "") {
            writer.uint32(74).string(message.weeklySessionId);
        }
        for (var _i = 0, _a = message.studentClassIds; _i < _a.length; _i++) {
            var v = _a[_i];
            writer.uint32(18).string(v);
        }
        for (var _b = 0, _c = message.unassignedStudentClassIds; _b < _c.length; _b++) {
            var v = _c[_b];
            writer.uint32(58).string(v);
        }
        if (message.hasStudents === true) {
            writer.uint32(80).bool(message.hasStudents);
        }
        if (message.studentCount !== 0) {
            writer.uint32(88).int32(message.studentCount);
        }
        if (message.availableSpotCount !== 0) {
            writer.uint32(96).int32(message.availableSpotCount);
        }
        if (message.teacherAdminId !== "") {
            writer.uint32(26).string(message.teacherAdminId);
        }
        if (message.capacityOverride !== 0) {
            writer.uint32(32).int32(message.capacityOverride);
        }
        if (message.startTimestampMs !== 0) {
            writer.uint32(40).int64(message.startTimestampMs);
        }
        if (message.endTimestampMs !== 0) {
            writer.uint32(48).int64(message.endTimestampMs);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseClassTeacherView();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.courseId = reader.string();
                    continue;
                case 9:
                    if (tag !== 74) {
                        break;
                    }
                    message.weeklySessionId = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.studentClassIds.push(reader.string());
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.unassignedStudentClassIds.push(reader.string());
                    continue;
                case 10:
                    if (tag !== 80) {
                        break;
                    }
                    message.hasStudents = reader.bool();
                    continue;
                case 11:
                    if (tag !== 88) {
                        break;
                    }
                    message.studentCount = reader.int32();
                    continue;
                case 12:
                    if (tag !== 96) {
                        break;
                    }
                    message.availableSpotCount = reader.int32();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.teacherAdminId = reader.string();
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }
                    message.capacityOverride = reader.int32();
                    continue;
                case 5:
                    if (tag !== 40) {
                        break;
                    }
                    message.startTimestampMs = longToNumber(reader.int64());
                    continue;
                case 6:
                    if (tag !== 48) {
                        break;
                    }
                    message.endTimestampMs = longToNumber(reader.int64());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            courseId: isSet(object.courseId) ? String(object.courseId) : "",
            weeklySessionId: isSet(object.weeklySessionId) ? String(object.weeklySessionId) : "",
            studentClassIds: Array.isArray(object === null || object === void 0 ? void 0 : object.studentClassIds) ? object.studentClassIds.map(function (e) { return String(e); }) : [],
            unassignedStudentClassIds: Array.isArray(object === null || object === void 0 ? void 0 : object.unassignedStudentClassIds)
                ? object.unassignedStudentClassIds.map(function (e) { return String(e); })
                : [],
            hasStudents: isSet(object.hasStudents) ? Boolean(object.hasStudents) : false,
            studentCount: isSet(object.studentCount) ? Number(object.studentCount) : 0,
            availableSpotCount: isSet(object.availableSpotCount) ? Number(object.availableSpotCount) : 0,
            teacherAdminId: isSet(object.teacherAdminId) ? String(object.teacherAdminId) : "",
            capacityOverride: isSet(object.capacityOverride) ? Number(object.capacityOverride) : 0,
            startTimestampMs: isSet(object.startTimestampMs) ? Number(object.startTimestampMs) : 0,
            endTimestampMs: isSet(object.endTimestampMs) ? Number(object.endTimestampMs) : 0,
        };
    },
    toJSON: function (message) {
        var _a, _b;
        var obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.courseId !== "") {
            obj.courseId = message.courseId;
        }
        if (message.weeklySessionId !== "") {
            obj.weeklySessionId = message.weeklySessionId;
        }
        if ((_a = message.studentClassIds) === null || _a === void 0 ? void 0 : _a.length) {
            obj.studentClassIds = message.studentClassIds;
        }
        if ((_b = message.unassignedStudentClassIds) === null || _b === void 0 ? void 0 : _b.length) {
            obj.unassignedStudentClassIds = message.unassignedStudentClassIds;
        }
        if (message.hasStudents === true) {
            obj.hasStudents = message.hasStudents;
        }
        if (message.studentCount !== 0) {
            obj.studentCount = Math.round(message.studentCount);
        }
        if (message.availableSpotCount !== 0) {
            obj.availableSpotCount = Math.round(message.availableSpotCount);
        }
        if (message.teacherAdminId !== "") {
            obj.teacherAdminId = message.teacherAdminId;
        }
        if (message.capacityOverride !== 0) {
            obj.capacityOverride = Math.round(message.capacityOverride);
        }
        if (message.startTimestampMs !== 0) {
            obj.startTimestampMs = Math.round(message.startTimestampMs);
        }
        if (message.endTimestampMs !== 0) {
            obj.endTimestampMs = Math.round(message.endTimestampMs);
        }
        return obj;
    },
    create: function (base) {
        return exports.ClassTeacherView.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        var message = createBaseClassTeacherView();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.courseId = (_b = object.courseId) !== null && _b !== void 0 ? _b : "";
        message.weeklySessionId = (_c = object.weeklySessionId) !== null && _c !== void 0 ? _c : "";
        message.studentClassIds = ((_d = object.studentClassIds) === null || _d === void 0 ? void 0 : _d.map(function (e) { return e; })) || [];
        message.unassignedStudentClassIds = ((_e = object.unassignedStudentClassIds) === null || _e === void 0 ? void 0 : _e.map(function (e) { return e; })) || [];
        message.hasStudents = (_f = object.hasStudents) !== null && _f !== void 0 ? _f : false;
        message.studentCount = (_g = object.studentCount) !== null && _g !== void 0 ? _g : 0;
        message.availableSpotCount = (_h = object.availableSpotCount) !== null && _h !== void 0 ? _h : 0;
        message.teacherAdminId = (_j = object.teacherAdminId) !== null && _j !== void 0 ? _j : "";
        message.capacityOverride = (_k = object.capacityOverride) !== null && _k !== void 0 ? _k : 0;
        message.startTimestampMs = (_l = object.startTimestampMs) !== null && _l !== void 0 ? _l : 0;
        message.endTimestampMs = (_m = object.endTimestampMs) !== null && _m !== void 0 ? _m : 0;
        return message;
    },
};
var tsProtoGlobalThis = (function () {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToNumber(long) {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
