<template>
  <div class="navatation-bar boxH cr-center jc-between bg-main">
    <div class="boxH">
      <div class="title-container">
        <img :src="logo" class="logo" />
        <div class="title">After School Hero</div>
      </div>

      <div class="boxH" v-if="isLogin">
        <router-link
          v-if="isMember"
          to="/dashboard"
          class="router boxH jccr-center"
          :class="{ 'current-route': isCurrentRoute('/dashboard') }"
        >
          {{ t('navigationBarItem.dashboard') }}
        </router-link>
        <router-link
          v-if="isMember"
          to="/class-calendar"
          class="router boxH jccr-center"
          :class="{ 'current-route': isCurrentRoute('/class-calendar') }"
        >
          {{ t('navigationBarItem.calendar') }}
        </router-link>
        <router-link
          v-if="isTeacherAdmin"
          to="/teacher-calendar"
          class="router boxH jccr-center"
          :class="{ 'current-route': isCurrentRoute('/teacher-calendar') }"
        >
          {{ t('navigationBarItem.calendar') }}
        </router-link>
        <router-link
          to="/chat-online"
          class="router boxH jccr-center"
          :class="{ 'current-route': isCurrentRoute('/chat-online') }"
        >
          {{ t('navigationBarItem.inbox') }}
        </router-link>
      </div>
    </div>

    <div class="right-btn boxH">
      <div class="right-btn-container">
        <div class="user-profile-container" v-if="isLogin">
          <p class="account-title pr-10">
            {{ store.state.user.kidName }}
          </p>
          <BaseAvatar
            class="user-profile-avatar"
            @click="drawerOnClick"
            :photo="store.state.user.thumbnail"
          />
        </div>
        <div>
          <el-select class="language-preferences" :placeholder="language.title">
            <template v-slot:prefix>
              <img :src="language.icon" />
            </template>
            <el-option
              v-for="(language, languageIndex) in languageOptions"
              :value="language.value"
              :key="languageIndex"
              @click="selectedLanguage(language)"
            >
              <div class="language-preferences-item">
                <img :src="language.icon" width="20" height="20" />
                <span>{{ language.title }}</span>
              </div>
            </el-option>
          </el-select>
        </div>
      </div>

      <el-drawer
        v-model="drawer"
        title="I am the title"
        class="pl-5 pt-5 drawer"
        :with-header="false"
        size="345"
        :before-close="handleClose"
      >
        <div class="d-flex items-center pl-5">
          <BaseAvatar
            class="user-profile-avatar"
            :photo="store.state.user.thumbnail"
          />
          <p class="account-name pl-10">
            {{ store.state.user.kidName }}
          </p>
        </div>
        <div class="d-flex justify-center mt-25">
          <hr class="divider" />
        </div>
        <div v-if="!isTeacherAdmin">
          <div
            v-for="(accountStudent, accountStudentIndex) in acountListStudent"
            :key="accountStudentIndex"
            class="mt-15 profile-menu-container"
            @click="accountStudent.method()"
          >
            <div class="d-flex align-center">
              <img v-if="accountStudent.icon" :src="accountStudent.icon" />
              <p class="profile-menu-text">{{ accountStudent.name }}</p>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-for="(accountTeacher, accountTeacherIndex) in acountListTeacher"
            :key="accountTeacherIndex"
            class="mt-15 profile-menu-container"
            @click="accountTeacher.method()"
          >
            <div class="d-flex align-center">
              <img v-if="accountTeacher.icon" :src="accountTeacher.icon" />
              <p class="profile-menu-text">{{ accountTeacher.name }}</p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-center mt-25">
          <hr class="divider" />
        </div>
        <div
          v-for="(setting, settingIndex) in settingsList"
          :key="settingIndex"
          class="mt-15 profile-menu-container"
          @click="setting.method"
        >
          <div class="d-flex align-center">
            <img v-if="setting.icon" :src="setting.icon" />
            <p class="profile-menu-text">{{ setting.name }}</p>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script setup>
import arrowRightImg from '@/assets/images/arrow_right_alt.svg';
import arrowDropDownImg from '@/assets/images/arrow_drop_down.svg';
import accountCircle from '@/assets/icons/icon_account_circle.svg';
import timelapse from '@/assets/icons/icon_timelapse-orange.svg';
import rateReview from '@/assets/icons/icon_rate_review.svg';
import settings from '@/assets/icons/icon_settings.svg';
import info from '@/assets/icons/icon_info-orange.svg';
import logout from '@/assets/icons/icon_logout.svg';
import unitedStatesFlag from '@/assets/icons/icon_united-states.svg';
import chinaFlag from '@/assets/icons/icon_china.svg';
import logo from '@/assets/images/logo.jpg';

import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useFirebaseAuth } from 'vuefire';
import { useRouter, useRoute } from 'vue-router';
import { ElMessageBox } from 'element-plus';
import { updateDoc, doc } from 'firebase/firestore';
import { userRef } from '@/api';
import { User, User_Language } from '@/api/protos/user';
import {
  StorageKey,
  getLocaleStroage,
  removeLocaleStroage,
} from '@/utils/localeStorageUtil';
import { Language } from '@/api/protos/lang';

/************************************************ 初始化数据 ************************************************/

const languageOptions = [
  {
    title: 'English',
    value: 'en-US',
    icon: unitedStatesFlag,
    languagePreference: Language.ENGLISH,
  },
  {
    title: '中文',
    value: 'zh-CN',
    icon: chinaFlag,
    languagePreference: Language.CHINESE,
  },
];

const { t, locale } = useI18n();
const router = useRouter();
const store = useStore();
const isLogin = computed(() => {
  return !!store.state.user.id;
});
const dialogVisible = ref(false);
const isMember = computed(() => {
  return !!store.state.user.membershipId || !store.state.user.isTeacherAdmin;
});
const isTeacherAdmin = computed(() => {
  return store.state.user.isTeacherAdmin;
});
const language = ref(languageOptions[0]);
const drawer = ref(false);
const acountListStudent = [
  {
    name: 'Your profile',
    icon: accountCircle,
    method: () => {
      router.push({
        path: '/perfect-member',
        query: { step: 2 },
      });

      drawerOnClick();
    },
  },
  {
    name: 'School end time',
    icon: timelapse,
    method: () => {
      router.push({
        path: '/perfect-member',
        query: { step: 5 },
      });

      drawerOnClick();
    },
  },
  {
    name: 'Reviews',
    icon: rateReview,
    method: () => {},
  },
];

const acountListTeacher = [
  {
    name: 'Reviews',
    icon: rateReview,
    method: () => {},
  },
];

const settingsList = [
  {
    name: 'Settings',
    icon: settings,
    method: () => {
      router.push('/student-settings');
      drawerOnClick();
    },
  },
  {
    name: 'Support entry',
    icon: info,
    method: () => {
      router.push('/support-entry');
      drawerOnClick();
    },
  },
  {
    name: 'Logout',
    icon: logout,
    method: () => {
      signOut();
      drawerOnClick();
    },
  },
];

function handleClose(done) {
  done();
}

onMounted(() => {
  document.removeEventListener('click', handleClickOutside);

  let userLocal = getLocaleStroage(StorageKey.USER);
  let languagePreference = isLogin.value ? store.state.user.languagePreference : 1;
  let filter = languageOptions.filter(
    (item) => item.languagePreference == languagePreference,
  );
  if (filter.length > 0) {
    language.value = filter[0];
  }
  locale.value = language.value.value;
});

function drawerOnClick() {
  drawer.value = !drawer.value;

  if (drawer.value) {
    document.addEventListener('click', handleClickOutside);
  } else {
    document.removeEventListener('click', handleClickOutside);
  }
}

const isCurrentRoute = (to) => {
  const currentRoute = useRoute();
  return to === currentRoute.path;
};

function handleClickOutside(event) {
  const drawerContainer = document.querySelector('.drawer');
  const avatarContainer = document.querySelector('.user-profile-avatar');

  if (
    drawer.value &&
    drawerContainer &&
    !drawerContainer.contains(event.target) &&
    !avatarContainer.contains(event.target)
  ) {
    drawer.value = !drawer.value;
    document.removeEventListener('click', handleClickOutside);
  }
}

function selectedLanguage(e) {
  locale.value = e.value;

  //  更新 languagePreference
  store.dispatch('setLanguage', e.languagePreference);
  if (isLogin.value) {
    const docRef = doc(userRef, store.state.user.id);
    updateDoc(docRef, { languagePreference: e.languagePreference });
  }

  language.value = e;
}

async function signOut() {
  ElMessageBox.confirm('Are you sure you want to log out?', 'Warning', {
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancel',
    type: 'warning',
    center: true,
  })
    .then(async () => {
      const auth = useFirebaseAuth();
      await auth.signOut();

      store.dispatch('logout');
      removeLocaleStroage(StorageKey.CURRENTSTEP);
      removeLocaleStroage(StorageKey.USER);
      router.push({
        name: 'sign-in',
      });
    })
    .catch(() => {});
}
</script>

<style lang="scss" scoped>
.navatation-bar {
  height: var(--navationBarHeight);
  // overflow: hidden;
  position: relative;
  padding: 0 9.375rem;

  .current-route {
    background-color: var(--foundation-orange-orange-400, #ffb156);
    border-radius: 1rem;
    padding: 0.3125rem 0.625rem;
    text-decoration-line: none !important;
  }

  .title-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: row;

    .logo {
      width: 40px;
      height: 40px;
    }

    .title {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 600;
      margin-right: 2.19rem;
    }
  }

  .router {
    color: #fff;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 500;
    text-decoration-line: underline;
    margin-right: 0.94rem;
  }

  .right-btn {
    ::v-deep(.el-input__wrapper) {
      box-shadow: none;
      border: none;
      background: transparent;
      font-size: 1rem;
      border-radius: 2rem;
    }

    .slect-lan {
      width: 10.75rem;
      height: 2.75rem;
      border-radius: 3.125rem;
      // padding: 0 0.625rem;
      margin-right: 1.56rem;

      .lan {
        color: var(--foundation-grey-grey-500, #302c2c);
        font-size: 1rem;
        font-weight: 400;
        margin-left: 0.5rem;
      }

      .country-img {
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 1.25rem;
      }
    }

    .router-sign-in {
      width: 8.6875rem;
      height: 2.75rem;
      border-radius: 2.75rem;

      .sign-in {
        font-size: 1rem;
        font-weight: 600;
        margin-right: 0.625rem;
      }
    }

    .arrow-right-img,
    .arrow-drop-img {
      width: 1.5rem;
      height: 1.5rem;
    }

    .account-title {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
    }
  }

  ::v-deep(.el-drawer.rtl) {
    top: 55.5px;
    position: fixed;
    box-shadow: none;
    border-left: 1px solid var(--foundation-grey-grey-50, #eaeaea);
  }

  .drawer {
    .account-name {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
    }

    .divider {
      width: 295px;
      height: 1px;
      border: 0.5px solid #eaeaea;
    }

    .profile-menu-container {
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: rgb(255, 158, 44, 0.1);
      }
    }

    .profile-menu-text {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 15px;
    }
  }

  ::v-deep(.el-overlay) {
    position: relative;
    background-color: transparent;
  }
}

.right-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.user-profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .user-profile-avatar {
    cursor: pointer;
    width: 40px;
    height: 40px;
  }
}
.language-preferences {
  display: flex;
  width: 10rem;
  background-color: white;
  border-radius: 50px;
}

.language-preferences-item {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.language-option {
  font-size: 16px;
  height: 36px;
}

.language-btn {
  width: 100%;
  height: 100%;
}
</style>
