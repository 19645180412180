"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Class = exports.statusToJSON = exports.statusFromJSON = exports.Status = exports.classTypeToJSON = exports.classTypeFromJSON = exports.ClassType = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
var course_1 = require("./course");
var photo_1 = require("./photo");
var review_1 = require("./review");
var Long = require("long");
exports.protobufPackage = "com.afterschool.hero";
var ClassType;
(function (ClassType) {
    ClassType[ClassType["TYPE_UNKNOWN"] = 0] = "TYPE_UNKNOWN";
    ClassType[ClassType["TRIAL"] = 2] = "TRIAL";
    ClassType[ClassType["PAID_MONTHLY"] = 3] = "PAID_MONTHLY";
    ClassType[ClassType["PAID_YEARLY"] = 4] = "PAID_YEARLY";
    ClassType[ClassType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ClassType || (exports.ClassType = ClassType = {}));
function classTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "TYPE_UNKNOWN":
            return ClassType.TYPE_UNKNOWN;
        case 2:
        case "TRIAL":
            return ClassType.TRIAL;
        case 3:
        case "PAID_MONTHLY":
            return ClassType.PAID_MONTHLY;
        case 4:
        case "PAID_YEARLY":
            return ClassType.PAID_YEARLY;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ClassType.UNRECOGNIZED;
    }
}
exports.classTypeFromJSON = classTypeFromJSON;
function classTypeToJSON(object) {
    switch (object) {
        case ClassType.TYPE_UNKNOWN:
            return "TYPE_UNKNOWN";
        case ClassType.TRIAL:
            return "TRIAL";
        case ClassType.PAID_MONTHLY:
            return "PAID_MONTHLY";
        case ClassType.PAID_YEARLY:
            return "PAID_YEARLY";
        case ClassType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.classTypeToJSON = classTypeToJSON;
var Status;
(function (Status) {
    Status[Status["STATUS_UNKNOWN"] = 0] = "STATUS_UNKNOWN";
    Status[Status["PLANNED"] = 2] = "PLANNED";
    Status[Status["CONFIRMED_BY_STUDENT"] = 3] = "CONFIRMED_BY_STUDENT";
    Status[Status["CANCELLED_BY_STUDENT"] = 4] = "CANCELLED_BY_STUDENT";
    Status[Status["CANCELLED_BY_TEACHER"] = 5] = "CANCELLED_BY_TEACHER";
    Status[Status["RESCHEDULED"] = 6] = "RESCHEDULED";
    Status[Status["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Status || (exports.Status = Status = {}));
function statusFromJSON(object) {
    switch (object) {
        case 0:
        case "STATUS_UNKNOWN":
            return Status.STATUS_UNKNOWN;
        case 2:
        case "PLANNED":
            return Status.PLANNED;
        case 3:
        case "CONFIRMED_BY_STUDENT":
            return Status.CONFIRMED_BY_STUDENT;
        case 4:
        case "CANCELLED_BY_STUDENT":
            return Status.CANCELLED_BY_STUDENT;
        case 5:
        case "CANCELLED_BY_TEACHER":
            return Status.CANCELLED_BY_TEACHER;
        case 6:
        case "RESCHEDULED":
            return Status.RESCHEDULED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Status.UNRECOGNIZED;
    }
}
exports.statusFromJSON = statusFromJSON;
function statusToJSON(object) {
    switch (object) {
        case Status.STATUS_UNKNOWN:
            return "STATUS_UNKNOWN";
        case Status.PLANNED:
            return "PLANNED";
        case Status.CONFIRMED_BY_STUDENT:
            return "CONFIRMED_BY_STUDENT";
        case Status.CANCELLED_BY_STUDENT:
            return "CANCELLED_BY_STUDENT";
        case Status.CANCELLED_BY_TEACHER:
            return "CANCELLED_BY_TEACHER";
        case Status.RESCHEDULED:
            return "RESCHEDULED";
        case Status.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.statusToJSON = statusToJSON;
function createBaseClass() {
    return {
        id: "",
        weeklySessionId: "",
        courseId: "",
        courseCover: undefined,
        courseTitle: "",
        courseTitleChinese: "",
        downloadUrl: "",
        courseType: 0,
        type: 0,
        status: 0,
        studentId: "",
        studentName: "",
        studentThumbnail: undefined,
        teacherId: "",
        teacherName: "",
        teacherThumbnail: undefined,
        courseDiscussionId: "",
        startTimestampMs: 0,
        endTimestampMs: 0,
        questionRequired: false,
        review: undefined,
    };
}
exports.Class = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.weeklySessionId !== "") {
            writer.uint32(154).string(message.weeklySessionId);
        }
        if (message.courseId !== "") {
            writer.uint32(18).string(message.courseId);
        }
        if (message.courseCover !== undefined) {
            photo_1.Photo.encode(message.courseCover, writer.uint32(98).fork()).ldelim();
        }
        if (message.courseTitle !== "") {
            writer.uint32(146).string(message.courseTitle);
        }
        if (message.courseTitleChinese !== "") {
            writer.uint32(210).string(message.courseTitleChinese);
        }
        if (message.downloadUrl !== "") {
            writer.uint32(186).string(message.downloadUrl);
        }
        if (message.courseType !== 0) {
            writer.uint32(192).int32(message.courseType);
        }
        if (message.type !== 0) {
            writer.uint32(104).int32(message.type);
        }
        if (message.status !== 0) {
            writer.uint32(112).int32(message.status);
        }
        if (message.studentId !== "") {
            writer.uint32(26).string(message.studentId);
        }
        if (message.studentName !== "") {
            writer.uint32(58).string(message.studentName);
        }
        if (message.studentThumbnail !== undefined) {
            photo_1.Photo.encode(message.studentThumbnail, writer.uint32(66).fork()).ldelim();
        }
        if (message.teacherId !== "") {
            writer.uint32(34).string(message.teacherId);
        }
        if (message.teacherName !== "") {
            writer.uint32(42).string(message.teacherName);
        }
        if (message.teacherThumbnail !== undefined) {
            photo_1.Photo.encode(message.teacherThumbnail, writer.uint32(74).fork()).ldelim();
        }
        if (message.courseDiscussionId !== "") {
            writer.uint32(138).string(message.courseDiscussionId);
        }
        if (message.startTimestampMs !== 0) {
            writer.uint32(120).int64(message.startTimestampMs);
        }
        if (message.endTimestampMs !== 0) {
            writer.uint32(128).int64(message.endTimestampMs);
        }
        if (message.questionRequired === true) {
            writer.uint32(176).bool(message.questionRequired);
        }
        if (message.review !== undefined) {
            review_1.Review.encode(message.review, writer.uint32(170).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseClass();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 19:
                    if (tag !== 154) {
                        break;
                    }
                    message.weeklySessionId = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.courseId = reader.string();
                    continue;
                case 12:
                    if (tag !== 98) {
                        break;
                    }
                    message.courseCover = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 18:
                    if (tag !== 146) {
                        break;
                    }
                    message.courseTitle = reader.string();
                    continue;
                case 26:
                    if (tag !== 210) {
                        break;
                    }
                    message.courseTitleChinese = reader.string();
                    continue;
                case 23:
                    if (tag !== 186) {
                        break;
                    }
                    message.downloadUrl = reader.string();
                    continue;
                case 24:
                    if (tag !== 192) {
                        break;
                    }
                    message.courseType = reader.int32();
                    continue;
                case 13:
                    if (tag !== 104) {
                        break;
                    }
                    message.type = reader.int32();
                    continue;
                case 14:
                    if (tag !== 112) {
                        break;
                    }
                    message.status = reader.int32();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.studentId = reader.string();
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.studentName = reader.string();
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.studentThumbnail = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.teacherId = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.teacherName = reader.string();
                    continue;
                case 9:
                    if (tag !== 74) {
                        break;
                    }
                    message.teacherThumbnail = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 17:
                    if (tag !== 138) {
                        break;
                    }
                    message.courseDiscussionId = reader.string();
                    continue;
                case 15:
                    if (tag !== 120) {
                        break;
                    }
                    message.startTimestampMs = longToNumber(reader.int64());
                    continue;
                case 16:
                    if (tag !== 128) {
                        break;
                    }
                    message.endTimestampMs = longToNumber(reader.int64());
                    continue;
                case 22:
                    if (tag !== 176) {
                        break;
                    }
                    message.questionRequired = reader.bool();
                    continue;
                case 21:
                    if (tag !== 170) {
                        break;
                    }
                    message.review = review_1.Review.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            weeklySessionId: isSet(object.weeklySessionId) ? String(object.weeklySessionId) : "",
            courseId: isSet(object.courseId) ? String(object.courseId) : "",
            courseCover: isSet(object.courseCover) ? photo_1.Photo.fromJSON(object.courseCover) : undefined,
            courseTitle: isSet(object.courseTitle) ? String(object.courseTitle) : "",
            courseTitleChinese: isSet(object.courseTitleChinese) ? String(object.courseTitleChinese) : "",
            downloadUrl: isSet(object.downloadUrl) ? String(object.downloadUrl) : "",
            courseType: isSet(object.courseType) ? (0, course_1.typeFromJSON)(object.courseType) : 0,
            type: isSet(object.type) ? classTypeFromJSON(object.type) : 0,
            status: isSet(object.status) ? statusFromJSON(object.status) : 0,
            studentId: isSet(object.studentId) ? String(object.studentId) : "",
            studentName: isSet(object.studentName) ? String(object.studentName) : "",
            studentThumbnail: isSet(object.studentThumbnail) ? photo_1.Photo.fromJSON(object.studentThumbnail) : undefined,
            teacherId: isSet(object.teacherId) ? String(object.teacherId) : "",
            teacherName: isSet(object.teacherName) ? String(object.teacherName) : "",
            teacherThumbnail: isSet(object.teacherThumbnail) ? photo_1.Photo.fromJSON(object.teacherThumbnail) : undefined,
            courseDiscussionId: isSet(object.courseDiscussionId) ? String(object.courseDiscussionId) : "",
            startTimestampMs: isSet(object.startTimestampMs) ? Number(object.startTimestampMs) : 0,
            endTimestampMs: isSet(object.endTimestampMs) ? Number(object.endTimestampMs) : 0,
            questionRequired: isSet(object.questionRequired) ? Boolean(object.questionRequired) : false,
            review: isSet(object.review) ? review_1.Review.fromJSON(object.review) : undefined,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.weeklySessionId !== "") {
            obj.weeklySessionId = message.weeklySessionId;
        }
        if (message.courseId !== "") {
            obj.courseId = message.courseId;
        }
        if (message.courseCover !== undefined) {
            obj.courseCover = photo_1.Photo.toJSON(message.courseCover);
        }
        if (message.courseTitle !== "") {
            obj.courseTitle = message.courseTitle;
        }
        if (message.courseTitleChinese !== "") {
            obj.courseTitleChinese = message.courseTitleChinese;
        }
        if (message.downloadUrl !== "") {
            obj.downloadUrl = message.downloadUrl;
        }
        if (message.courseType !== 0) {
            obj.courseType = (0, course_1.typeToJSON)(message.courseType);
        }
        if (message.type !== 0) {
            obj.type = classTypeToJSON(message.type);
        }
        if (message.status !== 0) {
            obj.status = statusToJSON(message.status);
        }
        if (message.studentId !== "") {
            obj.studentId = message.studentId;
        }
        if (message.studentName !== "") {
            obj.studentName = message.studentName;
        }
        if (message.studentThumbnail !== undefined) {
            obj.studentThumbnail = photo_1.Photo.toJSON(message.studentThumbnail);
        }
        if (message.teacherId !== "") {
            obj.teacherId = message.teacherId;
        }
        if (message.teacherName !== "") {
            obj.teacherName = message.teacherName;
        }
        if (message.teacherThumbnail !== undefined) {
            obj.teacherThumbnail = photo_1.Photo.toJSON(message.teacherThumbnail);
        }
        if (message.courseDiscussionId !== "") {
            obj.courseDiscussionId = message.courseDiscussionId;
        }
        if (message.startTimestampMs !== 0) {
            obj.startTimestampMs = Math.round(message.startTimestampMs);
        }
        if (message.endTimestampMs !== 0) {
            obj.endTimestampMs = Math.round(message.endTimestampMs);
        }
        if (message.questionRequired === true) {
            obj.questionRequired = message.questionRequired;
        }
        if (message.review !== undefined) {
            obj.review = review_1.Review.toJSON(message.review);
        }
        return obj;
    },
    create: function (base) {
        return exports.Class.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
        var message = createBaseClass();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.weeklySessionId = (_b = object.weeklySessionId) !== null && _b !== void 0 ? _b : "";
        message.courseId = (_c = object.courseId) !== null && _c !== void 0 ? _c : "";
        message.courseCover = (object.courseCover !== undefined && object.courseCover !== null)
            ? photo_1.Photo.fromPartial(object.courseCover)
            : undefined;
        message.courseTitle = (_d = object.courseTitle) !== null && _d !== void 0 ? _d : "";
        message.courseTitleChinese = (_e = object.courseTitleChinese) !== null && _e !== void 0 ? _e : "";
        message.downloadUrl = (_f = object.downloadUrl) !== null && _f !== void 0 ? _f : "";
        message.courseType = (_g = object.courseType) !== null && _g !== void 0 ? _g : 0;
        message.type = (_h = object.type) !== null && _h !== void 0 ? _h : 0;
        message.status = (_j = object.status) !== null && _j !== void 0 ? _j : 0;
        message.studentId = (_k = object.studentId) !== null && _k !== void 0 ? _k : "";
        message.studentName = (_l = object.studentName) !== null && _l !== void 0 ? _l : "";
        message.studentThumbnail = (object.studentThumbnail !== undefined && object.studentThumbnail !== null)
            ? photo_1.Photo.fromPartial(object.studentThumbnail)
            : undefined;
        message.teacherId = (_m = object.teacherId) !== null && _m !== void 0 ? _m : "";
        message.teacherName = (_o = object.teacherName) !== null && _o !== void 0 ? _o : "";
        message.teacherThumbnail = (object.teacherThumbnail !== undefined && object.teacherThumbnail !== null)
            ? photo_1.Photo.fromPartial(object.teacherThumbnail)
            : undefined;
        message.courseDiscussionId = (_p = object.courseDiscussionId) !== null && _p !== void 0 ? _p : "";
        message.startTimestampMs = (_q = object.startTimestampMs) !== null && _q !== void 0 ? _q : 0;
        message.endTimestampMs = (_r = object.endTimestampMs) !== null && _r !== void 0 ? _r : 0;
        message.questionRequired = (_s = object.questionRequired) !== null && _s !== void 0 ? _s : false;
        message.review = (object.review !== undefined && object.review !== null)
            ? review_1.Review.fromPartial(object.review)
            : undefined;
        return message;
    },
};
var tsProtoGlobalThis = (function () {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToNumber(long) {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
