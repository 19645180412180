import { doc, getDoc } from 'firebase/firestore';

console.log('permission.js call on');

import router from './router';
import store from './store';
import { userRef, studentAvailRef } from './api';
import { getCurrentUser } from 'vuefire';

router.beforeEach(async (to, from, next) => {
  console.log('to ', to, to.meta, to.meta.requiresAuth);
  //  1. 判断路径是否存在
  if (to.matched.length == 0) {
    return next('/');
  }
  const currentUser = await getCurrentUser();
  //  2. 判断路径是否需要登录
  if (to.meta.requiresAuth) {
    if (!currentUser) {
      return next('/');
    }
  }

  //  获取用户信息
  if (currentUser && !store.state.user.id) {
    const docSnap = await getDoc(doc(userRef, currentUser.uid));
    let userInfo = docSnap.exists() ? docSnap.data() : {};
    userInfo.id = currentUser.uid;
    const studentAvailDocSnap = await getDoc(
      doc(studentAvailRef, currentUser.uid),
    );
    let studentAvail = studentAvailDocSnap.exists()
      ? studentAvailDocSnap.data()
      : { userId: currentUser.uid };
    store.dispatch('setUser', userInfo);
    store.dispatch('setStudentAvail', studentAvail);
    console.log('user is ', userInfo);
  }
  const isTeacher = store.state.user.isTeacherAdmin;
  //  3. 判断是否是老师
  if (isTeacher && to.path == '/') {
    return next('/teacher-calendar');
  }

  //  4. 完成付费，且访问 home 跳转 dashboard
  if (store.state.user.membershipId && to.path == '/') {
    return next('/dashboard');
  }
  //  4. 按照路由跳转
  return next();
});
