import iconStar from './icon_star.svg';
import iconLabelOrange from './icon_label_orange.svg';
import iconLabelBlue from './icon_label_blue.svg';
import iconClock from './icon_clock.svg';
import iconUser from './icon_user.svg';
import iconLanguage from './icon_language.svg';
import iconMessage from './icon_message.svg';
import iconTimelapse from './icon_timelapse.svg';
import iconTimelapseBlue from './icon_timelapse_blue.svg';
import iconInfo from './icon_info.svg';
import iconSend from './icon_send.svg';
import iconCamera from './icon_camera.svg';
import iconUpload from './icon_upload.svg';
import iconRight from './icon_chevron-right.svg';
import iconDelete from './icon_delete.svg';
import iconAdd from './icon_add-circle-outline.svg';
import iconClose from './icon_close.svg';
import iconGreyRight from './icon_chevron-right_grey.svg';
import iconSupportFlag from './icon_support-flag.svg';
import iconSupportPayment from './icon_support-payment.svg';
import iconSupportBook from './icon_support-book.svg';
import iconAvatar from './icon__avatar.svg';
import iconArrowLeftOrange from './icon_arrow-left_orange.svg';
import iconArrowRightOrange from './icon_arrow-right_orange.svg';

export default {
  iconSend,
  iconCamera,
  iconTimelapseBlue,
  iconTimelapse,
  iconMessage,
  iconStar,
  iconLabelOrange,
  iconLabelBlue,
  iconClock,
  iconUser,
  iconLanguage,
  iconInfo,
  iconUpload,
  iconRight,
  iconDelete,
  iconAdd,
  iconClose,
  iconGreyRight,
  iconSupportBook,
  iconSupportFlag,
  iconSupportPayment,
  iconAvatar,
  iconArrowLeftOrange,
  iconArrowRightOrange
};
