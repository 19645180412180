import { createStore } from 'vuex';
import { User } from '@/api/protos/user';
import { StudentAvail } from '@/api/protos/student_avail';

const store = createStore({
  state: () => {
    return {
      user: User.create({
        languagePreference: 1
      }),
      studentAvail: StudentAvail.create({}),
      systemInfo: {
        scale: 1,
        fontSize: 12,
      },
    };
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = User.create(user);
      state.user.birthYear =
        state.user.birthYear == 0 ? '' : state.user.birthYear;
      state.user.birthMonth =
        state.user.birthMonth == 0 ? '' : state.user.birthMonth;
    },
    SET_LANGUAGE: (state, language) => {
      state.user.languagePreference = language;
    },
    SET_STUDENTAVAIL: (state, studentAvail) => {
      state.studentAvail = StudentAvail.create(studentAvail);
      state.studentAvail.schoolEndHour =
        state.studentAvail.schoolEndHour > 0
          ? state.studentAvail.schoolEndHour
          : 15;
    },
    SET_SCHOOL_END: (state, endTime) => {
      state.studentAvail.schoolEndHour = endTime;
    },
    SET_SCALE: (state, scale) => {
      state.systemInfo.scale = scale;
    },
    SET_FONTSIZE: (state, fontSize) => {
      state.systemInfo.fontSize = fontSize;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language);
    },
    setStudentAvail({ commit }, studentAvail) {
      commit('SET_STUDENTAVAIL', studentAvail);
    },
    setSchoolEndHour({ commit }, endTime) {
      commit('SET_SCHOOL_END', endTime);
    },
    logout({ commit }) {
      commit('SET_USER', {});
      commit('SET_STUDENTAVAIL', {});
    },
    setScale({ commit }, scale) {
      commit('SET_SCALE', scale);
    },
    setFontSize({ commit }, fontSize) {
      commit('SET_FONTSIZE', fontSize);
    },
  },
  getters: {},
});

export default store;
