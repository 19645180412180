"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User_InterestToCourseEntry = exports.User = exports.user_LanguageToJSON = exports.user_LanguageFromJSON = exports.User_Language = exports.user_SexToJSON = exports.user_SexFromJSON = exports.User_Sex = exports.user_PersonalityToJSON = exports.user_PersonalityFromJSON = exports.User_Personality = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
var city_1 = require("./city");
var interest_1 = require("./interest");
var photo_1 = require("./photo");
exports.protobufPackage = "com.afterschool.hero";
var User_Personality;
(function (User_Personality) {
    User_Personality[User_Personality["PERSONALITY_UNKNOWN"] = 0] = "PERSONALITY_UNKNOWN";
    User_Personality[User_Personality["SHY"] = 1] = "SHY";
    User_Personality[User_Personality["OUT_GOING"] = 2] = "OUT_GOING";
    User_Personality[User_Personality["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(User_Personality || (exports.User_Personality = User_Personality = {}));
function user_PersonalityFromJSON(object) {
    switch (object) {
        case 0:
        case "PERSONALITY_UNKNOWN":
            return User_Personality.PERSONALITY_UNKNOWN;
        case 1:
        case "SHY":
            return User_Personality.SHY;
        case 2:
        case "OUT_GOING":
            return User_Personality.OUT_GOING;
        case -1:
        case "UNRECOGNIZED":
        default:
            return User_Personality.UNRECOGNIZED;
    }
}
exports.user_PersonalityFromJSON = user_PersonalityFromJSON;
function user_PersonalityToJSON(object) {
    switch (object) {
        case User_Personality.PERSONALITY_UNKNOWN:
            return "PERSONALITY_UNKNOWN";
        case User_Personality.SHY:
            return "SHY";
        case User_Personality.OUT_GOING:
            return "OUT_GOING";
        case User_Personality.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.user_PersonalityToJSON = user_PersonalityToJSON;
var User_Sex;
(function (User_Sex) {
    User_Sex[User_Sex["SEX_UNKNOWN"] = 0] = "SEX_UNKNOWN";
    User_Sex[User_Sex["BOY"] = 1] = "BOY";
    User_Sex[User_Sex["GIRL"] = 2] = "GIRL";
    User_Sex[User_Sex["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(User_Sex || (exports.User_Sex = User_Sex = {}));
function user_SexFromJSON(object) {
    switch (object) {
        case 0:
        case "SEX_UNKNOWN":
            return User_Sex.SEX_UNKNOWN;
        case 1:
        case "BOY":
            return User_Sex.BOY;
        case 2:
        case "GIRL":
            return User_Sex.GIRL;
        case -1:
        case "UNRECOGNIZED":
        default:
            return User_Sex.UNRECOGNIZED;
    }
}
exports.user_SexFromJSON = user_SexFromJSON;
function user_SexToJSON(object) {
    switch (object) {
        case User_Sex.SEX_UNKNOWN:
            return "SEX_UNKNOWN";
        case User_Sex.BOY:
            return "BOY";
        case User_Sex.GIRL:
            return "GIRL";
        case User_Sex.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.user_SexToJSON = user_SexToJSON;
var User_Language;
(function (User_Language) {
    User_Language[User_Language["ENGLISH"] = 0] = "ENGLISH";
    User_Language[User_Language["CHINESE"] = 1] = "CHINESE";
    User_Language[User_Language["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(User_Language || (exports.User_Language = User_Language = {}));
function user_LanguageFromJSON(object) {
    switch (object) {
        case 0:
        case "ENGLISH":
            return User_Language.ENGLISH;
        case 1:
        case "CHINESE":
            return User_Language.CHINESE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return User_Language.UNRECOGNIZED;
    }
}
exports.user_LanguageFromJSON = user_LanguageFromJSON;
function user_LanguageToJSON(object) {
    switch (object) {
        case User_Language.ENGLISH:
            return "ENGLISH";
        case User_Language.CHINESE:
            return "CHINESE";
        case User_Language.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.user_LanguageToJSON = user_LanguageToJSON;
function createBaseUser() {
    return {
        id: "",
        parentName: "",
        kidName: "",
        membershipId: "",
        city: undefined,
        thumbnail: undefined,
        birthYear: 0,
        birthMonth: 0,
        personality: 0,
        sex: 0,
        interests: [],
        interestToCourse: {},
        countryCode: "",
        isTeacherAdmin: false,
        tokens: [],
        languagePreference: 0,
        englishFluency: 0,
        chineseFluency: 0,
    };
}
exports.User = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.parentName !== "") {
            writer.uint32(18).string(message.parentName);
        }
        if (message.kidName !== "") {
            writer.uint32(26).string(message.kidName);
        }
        if (message.membershipId !== "") {
            writer.uint32(34).string(message.membershipId);
        }
        if (message.city !== undefined) {
            city_1.City.encode(message.city, writer.uint32(42).fork()).ldelim();
        }
        if (message.thumbnail !== undefined) {
            photo_1.Photo.encode(message.thumbnail, writer.uint32(50).fork()).ldelim();
        }
        if (message.birthYear !== 0) {
            writer.uint32(56).int32(message.birthYear);
        }
        if (message.birthMonth !== 0) {
            writer.uint32(64).int32(message.birthMonth);
        }
        if (message.personality !== 0) {
            writer.uint32(72).int32(message.personality);
        }
        if (message.sex !== 0) {
            writer.uint32(80).int32(message.sex);
        }
        for (var _i = 0, _a = message.interests; _i < _a.length; _i++) {
            var v = _a[_i];
            interest_1.Interest.encode(v, writer.uint32(98).fork()).ldelim();
        }
        Object.entries(message.interestToCourse).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            exports.User_InterestToCourseEntry.encode({ key: key, value: value }, writer.uint32(154).fork()).ldelim();
        });
        if (message.countryCode !== "") {
            writer.uint32(106).string(message.countryCode);
        }
        if (message.isTeacherAdmin === true) {
            writer.uint32(112).bool(message.isTeacherAdmin);
        }
        for (var _b = 0, _c = message.tokens; _b < _c.length; _b++) {
            var v = _c[_b];
            writer.uint32(122).string(v);
        }
        if (message.languagePreference !== 0) {
            writer.uint32(128).int32(message.languagePreference);
        }
        if (message.englishFluency !== 0) {
            writer.uint32(136).int32(message.englishFluency);
        }
        if (message.chineseFluency !== 0) {
            writer.uint32(144).int32(message.chineseFluency);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseUser();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.parentName = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.kidName = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.membershipId = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.city = city_1.City.decode(reader, reader.uint32());
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.thumbnail = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 7:
                    if (tag !== 56) {
                        break;
                    }
                    message.birthYear = reader.int32();
                    continue;
                case 8:
                    if (tag !== 64) {
                        break;
                    }
                    message.birthMonth = reader.int32();
                    continue;
                case 9:
                    if (tag !== 72) {
                        break;
                    }
                    message.personality = reader.int32();
                    continue;
                case 10:
                    if (tag !== 80) {
                        break;
                    }
                    message.sex = reader.int32();
                    continue;
                case 12:
                    if (tag !== 98) {
                        break;
                    }
                    message.interests.push(interest_1.Interest.decode(reader, reader.uint32()));
                    continue;
                case 19:
                    if (tag !== 154) {
                        break;
                    }
                    var entry19 = exports.User_InterestToCourseEntry.decode(reader, reader.uint32());
                    if (entry19.value !== undefined) {
                        message.interestToCourse[entry19.key] = entry19.value;
                    }
                    continue;
                case 13:
                    if (tag !== 106) {
                        break;
                    }
                    message.countryCode = reader.string();
                    continue;
                case 14:
                    if (tag !== 112) {
                        break;
                    }
                    message.isTeacherAdmin = reader.bool();
                    continue;
                case 15:
                    if (tag !== 122) {
                        break;
                    }
                    message.tokens.push(reader.string());
                    continue;
                case 16:
                    if (tag !== 128) {
                        break;
                    }
                    message.languagePreference = reader.int32();
                    continue;
                case 17:
                    if (tag !== 136) {
                        break;
                    }
                    message.englishFluency = reader.int32();
                    continue;
                case 18:
                    if (tag !== 144) {
                        break;
                    }
                    message.chineseFluency = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            parentName: isSet(object.parentName) ? String(object.parentName) : "",
            kidName: isSet(object.kidName) ? String(object.kidName) : "",
            membershipId: isSet(object.membershipId) ? String(object.membershipId) : "",
            city: isSet(object.city) ? city_1.City.fromJSON(object.city) : undefined,
            thumbnail: isSet(object.thumbnail) ? photo_1.Photo.fromJSON(object.thumbnail) : undefined,
            birthYear: isSet(object.birthYear) ? Number(object.birthYear) : 0,
            birthMonth: isSet(object.birthMonth) ? Number(object.birthMonth) : 0,
            personality: isSet(object.personality) ? user_PersonalityFromJSON(object.personality) : 0,
            sex: isSet(object.sex) ? user_SexFromJSON(object.sex) : 0,
            interests: Array.isArray(object === null || object === void 0 ? void 0 : object.interests) ? object.interests.map(function (e) { return interest_1.Interest.fromJSON(e); }) : [],
            interestToCourse: isObject(object.interestToCourse)
                ? Object.entries(object.interestToCourse).reduce(function (acc, _a) {
                    var key = _a[0], value = _a[1];
                    acc[key] = String(value);
                    return acc;
                }, {})
                : {},
            countryCode: isSet(object.countryCode) ? String(object.countryCode) : "",
            isTeacherAdmin: isSet(object.isTeacherAdmin) ? Boolean(object.isTeacherAdmin) : false,
            tokens: Array.isArray(object === null || object === void 0 ? void 0 : object.tokens) ? object.tokens.map(function (e) { return String(e); }) : [],
            languagePreference: isSet(object.languagePreference) ? user_LanguageFromJSON(object.languagePreference) : 0,
            englishFluency: isSet(object.englishFluency) ? Number(object.englishFluency) : 0,
            chineseFluency: isSet(object.chineseFluency) ? Number(object.chineseFluency) : 0,
        };
    },
    toJSON: function (message) {
        var _a, _b;
        var obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.parentName !== "") {
            obj.parentName = message.parentName;
        }
        if (message.kidName !== "") {
            obj.kidName = message.kidName;
        }
        if (message.membershipId !== "") {
            obj.membershipId = message.membershipId;
        }
        if (message.city !== undefined) {
            obj.city = city_1.City.toJSON(message.city);
        }
        if (message.thumbnail !== undefined) {
            obj.thumbnail = photo_1.Photo.toJSON(message.thumbnail);
        }
        if (message.birthYear !== 0) {
            obj.birthYear = Math.round(message.birthYear);
        }
        if (message.birthMonth !== 0) {
            obj.birthMonth = Math.round(message.birthMonth);
        }
        if (message.personality !== 0) {
            obj.personality = user_PersonalityToJSON(message.personality);
        }
        if (message.sex !== 0) {
            obj.sex = user_SexToJSON(message.sex);
        }
        if ((_a = message.interests) === null || _a === void 0 ? void 0 : _a.length) {
            obj.interests = message.interests.map(function (e) { return interest_1.Interest.toJSON(e); });
        }
        if (message.interestToCourse) {
            var entries = Object.entries(message.interestToCourse);
            if (entries.length > 0) {
                obj.interestToCourse = {};
                entries.forEach(function (_a) {
                    var k = _a[0], v = _a[1];
                    obj.interestToCourse[k] = v;
                });
            }
        }
        if (message.countryCode !== "") {
            obj.countryCode = message.countryCode;
        }
        if (message.isTeacherAdmin === true) {
            obj.isTeacherAdmin = message.isTeacherAdmin;
        }
        if ((_b = message.tokens) === null || _b === void 0 ? void 0 : _b.length) {
            obj.tokens = message.tokens;
        }
        if (message.languagePreference !== 0) {
            obj.languagePreference = user_LanguageToJSON(message.languagePreference);
        }
        if (message.englishFluency !== 0) {
            obj.englishFluency = Math.round(message.englishFluency);
        }
        if (message.chineseFluency !== 0) {
            obj.chineseFluency = Math.round(message.chineseFluency);
        }
        return obj;
    },
    create: function (base) {
        return exports.User.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        var message = createBaseUser();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.parentName = (_b = object.parentName) !== null && _b !== void 0 ? _b : "";
        message.kidName = (_c = object.kidName) !== null && _c !== void 0 ? _c : "";
        message.membershipId = (_d = object.membershipId) !== null && _d !== void 0 ? _d : "";
        message.city = (object.city !== undefined && object.city !== null) ? city_1.City.fromPartial(object.city) : undefined;
        message.thumbnail = (object.thumbnail !== undefined && object.thumbnail !== null)
            ? photo_1.Photo.fromPartial(object.thumbnail)
            : undefined;
        message.birthYear = (_e = object.birthYear) !== null && _e !== void 0 ? _e : 0;
        message.birthMonth = (_f = object.birthMonth) !== null && _f !== void 0 ? _f : 0;
        message.personality = (_g = object.personality) !== null && _g !== void 0 ? _g : 0;
        message.sex = (_h = object.sex) !== null && _h !== void 0 ? _h : 0;
        message.interests = ((_j = object.interests) === null || _j === void 0 ? void 0 : _j.map(function (e) { return interest_1.Interest.fromPartial(e); })) || [];
        message.interestToCourse = Object.entries((_k = object.interestToCourse) !== null && _k !== void 0 ? _k : {}).reduce(function (acc, _a) {
            var key = _a[0], value = _a[1];
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        message.countryCode = (_l = object.countryCode) !== null && _l !== void 0 ? _l : "";
        message.isTeacherAdmin = (_m = object.isTeacherAdmin) !== null && _m !== void 0 ? _m : false;
        message.tokens = ((_o = object.tokens) === null || _o === void 0 ? void 0 : _o.map(function (e) { return e; })) || [];
        message.languagePreference = (_p = object.languagePreference) !== null && _p !== void 0 ? _p : 0;
        message.englishFluency = (_q = object.englishFluency) !== null && _q !== void 0 ? _q : 0;
        message.chineseFluency = (_r = object.chineseFluency) !== null && _r !== void 0 ? _r : 0;
        return message;
    },
};
function createBaseUser_InterestToCourseEntry() {
    return { key: "", value: "" };
}
exports.User_InterestToCourseEntry = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseUser_InterestToCourseEntry();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.key = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.value = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.key !== "") {
            obj.key = message.key;
        }
        if (message.value !== "") {
            obj.value = message.value;
        }
        return obj;
    },
    create: function (base) {
        return exports.User_InterestToCourseEntry.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b;
        var message = createBaseUser_InterestToCourseEntry();
        message.key = (_a = object.key) !== null && _a !== void 0 ? _a : "";
        message.value = (_b = object.value) !== null && _b !== void 0 ? _b : "";
        return message;
    },
};
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
