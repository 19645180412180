export default {
  teacher: {
    addNewCourse: 'Add New Course',
    courseDetail: 'Course Details',
    saveAndNext: 'Save and Next',
    weeklyCalendar: 'Weekly Schedule (Beijing Time)',
    studentOnlyClass: 'Show only classes that have students',

    class: {
      unscheduledStudents: 'List of unscheduled students',
      localTime: 'Your local time',
      scheduledStudents: 'List of scheduled students',
    }
  },
  welcome: {
    title: "Explore top children's activities",
    subTitle: 'and virtual courses',
    becomeAfterSchoolHero: 'Become After School Hero',
    memberInSteps: 'member in 5 steps',
    letsGo: "Let's go",
  },
  navigationBarItem: {
    dashboard: 'Dashboard',
    calendar: 'Calendar',
    inbox: 'Inbox',
  },
  signIn: 'Sign in',
  signOut: 'Sign out',
  started: 'Get Started',
  signInLong: 'Sign In To Your Account',
  phoneNumber: 'Phone number',
  verifyPhone: 'Verify phone number',
  codeSent: 'A code has been sent to your phone',
  resend: 'Resend',
  verify: 'Verify',
  dashboard: {
    message: 'Message',
    todayAgenda: "Today's agenda",
    agenda: 'Agenda',
    rate: 'Rate',
    questionnaire: 'Questionnaire',
    pastClasses: 'Past Classes',
  },
  classDetail: {
    oneToOne: "1-to-1",
    oneToMany: "Small Group Class",
    aiClass: "AI Instructor",
    videoClass: "Video Class",
    unknown: "Unknown Class Type"
  },
  image: 'Image',
  video: 'Video',
  reschedule: 'Reschedule',
  cancel: 'Cancel'
};
