"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Review = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
var photo_1 = require("./photo");
exports.protobufPackage = "com.afterschool.hero";
function createBaseReview() {
    return {
        id: "",
        classId: "",
        courseId: "",
        studentId: "",
        studentName: "",
        studentThumbnail: undefined,
        teacherAdminId: "",
        teacherName: "",
        teacherThumbnail: undefined,
        courseRate: 0,
        teacherRate: 0,
        scheduleRate: 0,
        description: "",
    };
}
exports.Review = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.classId !== "") {
            writer.uint32(18).string(message.classId);
        }
        if (message.courseId !== "") {
            writer.uint32(26).string(message.courseId);
        }
        if (message.studentId !== "") {
            writer.uint32(34).string(message.studentId);
        }
        if (message.studentName !== "") {
            writer.uint32(42).string(message.studentName);
        }
        if (message.studentThumbnail !== undefined) {
            photo_1.Photo.encode(message.studentThumbnail, writer.uint32(50).fork()).ldelim();
        }
        if (message.teacherAdminId !== "") {
            writer.uint32(58).string(message.teacherAdminId);
        }
        if (message.teacherName !== "") {
            writer.uint32(66).string(message.teacherName);
        }
        if (message.teacherThumbnail !== undefined) {
            photo_1.Photo.encode(message.teacherThumbnail, writer.uint32(74).fork()).ldelim();
        }
        if (message.courseRate !== 0) {
            writer.uint32(81).double(message.courseRate);
        }
        if (message.teacherRate !== 0) {
            writer.uint32(89).double(message.teacherRate);
        }
        if (message.scheduleRate !== 0) {
            writer.uint32(97).double(message.scheduleRate);
        }
        if (message.description !== "") {
            writer.uint32(106).string(message.description);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseReview();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.classId = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.courseId = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.studentId = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.studentName = reader.string();
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.studentThumbnail = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.teacherAdminId = reader.string();
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.teacherName = reader.string();
                    continue;
                case 9:
                    if (tag !== 74) {
                        break;
                    }
                    message.teacherThumbnail = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 10:
                    if (tag !== 81) {
                        break;
                    }
                    message.courseRate = reader.double();
                    continue;
                case 11:
                    if (tag !== 89) {
                        break;
                    }
                    message.teacherRate = reader.double();
                    continue;
                case 12:
                    if (tag !== 97) {
                        break;
                    }
                    message.scheduleRate = reader.double();
                    continue;
                case 13:
                    if (tag !== 106) {
                        break;
                    }
                    message.description = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            classId: isSet(object.classId) ? String(object.classId) : "",
            courseId: isSet(object.courseId) ? String(object.courseId) : "",
            studentId: isSet(object.studentId) ? String(object.studentId) : "",
            studentName: isSet(object.studentName) ? String(object.studentName) : "",
            studentThumbnail: isSet(object.studentThumbnail) ? photo_1.Photo.fromJSON(object.studentThumbnail) : undefined,
            teacherAdminId: isSet(object.teacherAdminId) ? String(object.teacherAdminId) : "",
            teacherName: isSet(object.teacherName) ? String(object.teacherName) : "",
            teacherThumbnail: isSet(object.teacherThumbnail) ? photo_1.Photo.fromJSON(object.teacherThumbnail) : undefined,
            courseRate: isSet(object.courseRate) ? Number(object.courseRate) : 0,
            teacherRate: isSet(object.teacherRate) ? Number(object.teacherRate) : 0,
            scheduleRate: isSet(object.scheduleRate) ? Number(object.scheduleRate) : 0,
            description: isSet(object.description) ? String(object.description) : "",
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.classId !== "") {
            obj.classId = message.classId;
        }
        if (message.courseId !== "") {
            obj.courseId = message.courseId;
        }
        if (message.studentId !== "") {
            obj.studentId = message.studentId;
        }
        if (message.studentName !== "") {
            obj.studentName = message.studentName;
        }
        if (message.studentThumbnail !== undefined) {
            obj.studentThumbnail = photo_1.Photo.toJSON(message.studentThumbnail);
        }
        if (message.teacherAdminId !== "") {
            obj.teacherAdminId = message.teacherAdminId;
        }
        if (message.teacherName !== "") {
            obj.teacherName = message.teacherName;
        }
        if (message.teacherThumbnail !== undefined) {
            obj.teacherThumbnail = photo_1.Photo.toJSON(message.teacherThumbnail);
        }
        if (message.courseRate !== 0) {
            obj.courseRate = message.courseRate;
        }
        if (message.teacherRate !== 0) {
            obj.teacherRate = message.teacherRate;
        }
        if (message.scheduleRate !== 0) {
            obj.scheduleRate = message.scheduleRate;
        }
        if (message.description !== "") {
            obj.description = message.description;
        }
        return obj;
    },
    create: function (base) {
        return exports.Review.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var message = createBaseReview();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.classId = (_b = object.classId) !== null && _b !== void 0 ? _b : "";
        message.courseId = (_c = object.courseId) !== null && _c !== void 0 ? _c : "";
        message.studentId = (_d = object.studentId) !== null && _d !== void 0 ? _d : "";
        message.studentName = (_e = object.studentName) !== null && _e !== void 0 ? _e : "";
        message.studentThumbnail = (object.studentThumbnail !== undefined && object.studentThumbnail !== null)
            ? photo_1.Photo.fromPartial(object.studentThumbnail)
            : undefined;
        message.teacherAdminId = (_f = object.teacherAdminId) !== null && _f !== void 0 ? _f : "";
        message.teacherName = (_g = object.teacherName) !== null && _g !== void 0 ? _g : "";
        message.teacherThumbnail = (object.teacherThumbnail !== undefined && object.teacherThumbnail !== null)
            ? photo_1.Photo.fromPartial(object.teacherThumbnail)
            : undefined;
        message.courseRate = (_h = object.courseRate) !== null && _h !== void 0 ? _h : 0;
        message.teacherRate = (_j = object.teacherRate) !== null && _j !== void 0 ? _j : 0;
        message.scheduleRate = (_k = object.scheduleRate) !== null && _k !== void 0 ? _k : 0;
        message.description = (_l = object.description) !== null && _l !== void 0 ? _l : "";
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
