<template>
  <div class="home">
    <div
      :class="[
        'container',
        !userId ? 'container-home-bg' : 'container-member-bg',
      ]"
    >
      <div class="container-mask boxV jccr-center" v-if="!userId">
        <div class="title color-ff">
          <div>{{ t('welcome.title') }}</div>
          <div>{{ t('welcome.subTitle') }}</div>
        </div>
        <router-link
          to="/sign-in"
          class="router-link get-started bg-main boxH jccr-center"
        >
          <div class="color-ff">{{ t('started') }}</div>
        </router-link>
        <router-link
          to="/sign-in"
          class="router-link sign-in bg-ff boxH jccr-center"
        >
          <div class="color-main">{{ t('signIn') }}</div>
        </router-link>
      </div>
      <div class="container-mask boxV jccr-center" v-if="userId">
        <div class="title color-ff">
          <div>{{ t('welcome.becomeAfterSchoolHero') }}</div>
          <div>{{ t('welcome.memberInSteps') }}</div>
        </div>
        <router-link
          to="/perfect-member"
          class="router-link perfect-member bg-main boxH jccr-center"
        >
          <div class="color-ff">{{ t('welcome.letsGo') }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { ref, onMounted, computed, reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { userRef } from '@/api';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getCurrentUser } from 'vuefire';

/************************************************ 初始化数据 ************************************************/
const { t } = useI18n();
const store = useStore();
const currentUser = ref({});
const userId = computed(() => store.state.user.id);

/************************************************  生命周期  ************************************************/
//  组件挂载完成后执行
onMounted(async () => {
  /**
   * TODO: 后续正式接入后做修改
   * 1. 测试signin页面时访问 http://localhost:8080
   * 2. 为了方便调试，home页面可以直接使用uid进行访问
   * http://localhost:8080/home/g2M172NgCRbLYTZDm5dcmvBrQlg2
   * http://localhost:8080/home/zfOUGYPhLthUgHWrwdgxrt9E7pM2
   */
  // console.log('onMounted call on', route.params.userId)
  currentUser.value = await getCurrentUser();
});

/************************************************  数据请求  ************************************************/
/************************************************  点击事件  ************************************************/
/************************************************  回调事件  ************************************************/
/************************************************  辅助方法  ************************************************/
</script>

<style lang="scss" scoped>
.home {
  height: calc(100vh - var(--navationBarHeight));

  .container-mask {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .container-home-bg {
    background-image: url('~@/assets/images/home_bg.png');
  }

  .container-member-bg {
    background-image: url('~@/assets/images/member_bg.png');
  }

  .container {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;

    .title {
      text-align: center;
      font-size: 2.3125rem;
      font-weight: 600;
      margin-bottom: 3.75rem;
    }

    .router-link {
      width: 22.1875rem;
      height: 3.6875rem;
      border-radius: 3.6875rem;
      font-weight: 500;
      margin-bottom: 1.56rem;
    }

    .get-started,
    .perfect-member {
      font-size: 1.375rem;
    }

    .sign-in {
      font-size: 1.3125rem;
    }
  }
}
</style>
