"use strict";
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.languageToJSON = exports.languageFromJSON = exports.Language = exports.protobufPackage = void 0;
exports.protobufPackage = "com.afterschool.hero";
var Language;
(function (Language) {
    Language[Language["ENGLISH"] = 0] = "ENGLISH";
    Language[Language["CHINESE"] = 1] = "CHINESE";
    Language[Language["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Language || (exports.Language = Language = {}));
function languageFromJSON(object) {
    switch (object) {
        case 0:
        case "ENGLISH":
            return Language.ENGLISH;
        case 1:
        case "CHINESE":
            return Language.CHINESE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Language.UNRECOGNIZED;
    }
}
exports.languageFromJSON = languageFromJSON;
function languageToJSON(object) {
    switch (object) {
        case Language.ENGLISH:
            return "ENGLISH";
        case Language.CHINESE:
            return "CHINESE";
        case Language.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.languageToJSON = languageToJSON;
