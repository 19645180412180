<template>
  <view class="app-main">
    <!-- <transition name="fade-transform" mode="out-in"> -->
    <router-view :name="name" />
    <!-- </transition> -->
  </view>
</template>
<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useFirebaseAuth } from 'vuefire';

defineProps({
  name: String,
});
const auth = useFirebaseAuth();

const router = useRouter();
const path = computed(() => router.name);
</script>

<style lang="scss" scoped>
.app-main {
  /*50 = navbar  */
  min-height: calc(100vh - var(--navationBarHeight));
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
</style>
