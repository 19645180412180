console.log('main.js call on');
import { ref, createApp } from 'vue';
import App from './App.vue';

import ElementPlus from 'element-plus';
import components from './components';
import './assets/styles/index.scss';
import './utils/dateFormat';

import router from './router';
import store from './store';
import i18n from './assets/i18n';

import { VueFire, VueFireAuth } from 'vuefire';
import firebaseApp from './api/firebase';

import './permission';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(i18n);
app.use(components);
app.use(VueFire, {
  // imported above but could also just be created here
  firebaseApp,
  modules: [
    // we will see other modules later on
    VueFireAuth(),
  ],
});

app.mount('#app');

import $ from 'jquery';
resizeBody();
window.onresize = () => {
  resizeBody();
};
function resizeBody() {
  const windowHeight = document.documentElement.clientHeight;
  const windowWidth = document.documentElement.clientWidth;

  const heightScale = (windowHeight * 1440) / (windowWidth * 1024);
  const fontSize = heightScale * 16 > 12 ? heightScale * 16 : 12;

  store.dispatch('setScale', heightScale);
  store.dispatch('setFontSize', fontSize);
  $(':root').css({
    'font-size': fontSize,
  });
}
