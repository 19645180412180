"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeeklySession = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
exports.protobufPackage = "com.afterschool.hero";
function createBaseWeeklySession() {
    return {
        id: "",
        courseId: "",
        teacherAdminId: "",
        timezone: "",
        capacity: 0,
        dayOfWeek: 0,
        startTime: "",
        endTime: "",
    };
}
exports.WeeklySession = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.courseId !== "") {
            writer.uint32(50).string(message.courseId);
        }
        if (message.teacherAdminId !== "") {
            writer.uint32(58).string(message.teacherAdminId);
        }
        if (message.timezone !== "") {
            writer.uint32(66).string(message.timezone);
        }
        if (message.capacity !== 0) {
            writer.uint32(16).int32(message.capacity);
        }
        if (message.dayOfWeek !== 0) {
            writer.uint32(24).int32(message.dayOfWeek);
        }
        if (message.startTime !== "") {
            writer.uint32(34).string(message.startTime);
        }
        if (message.endTime !== "") {
            writer.uint32(42).string(message.endTime);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseWeeklySession();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.courseId = reader.string();
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.teacherAdminId = reader.string();
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.timezone = reader.string();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.capacity = reader.int32();
                    continue;
                case 3:
                    if (tag !== 24) {
                        break;
                    }
                    message.dayOfWeek = reader.int32();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.startTime = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.endTime = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            courseId: isSet(object.courseId) ? String(object.courseId) : "",
            teacherAdminId: isSet(object.teacherAdminId) ? String(object.teacherAdminId) : "",
            timezone: isSet(object.timezone) ? String(object.timezone) : "",
            capacity: isSet(object.capacity) ? Number(object.capacity) : 0,
            dayOfWeek: isSet(object.dayOfWeek) ? Number(object.dayOfWeek) : 0,
            startTime: isSet(object.startTime) ? String(object.startTime) : "",
            endTime: isSet(object.endTime) ? String(object.endTime) : "",
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.courseId !== "") {
            obj.courseId = message.courseId;
        }
        if (message.teacherAdminId !== "") {
            obj.teacherAdminId = message.teacherAdminId;
        }
        if (message.timezone !== "") {
            obj.timezone = message.timezone;
        }
        if (message.capacity !== 0) {
            obj.capacity = Math.round(message.capacity);
        }
        if (message.dayOfWeek !== 0) {
            obj.dayOfWeek = Math.round(message.dayOfWeek);
        }
        if (message.startTime !== "") {
            obj.startTime = message.startTime;
        }
        if (message.endTime !== "") {
            obj.endTime = message.endTime;
        }
        return obj;
    },
    create: function (base) {
        return exports.WeeklySession.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var message = createBaseWeeklySession();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.courseId = (_b = object.courseId) !== null && _b !== void 0 ? _b : "";
        message.teacherAdminId = (_c = object.teacherAdminId) !== null && _c !== void 0 ? _c : "";
        message.timezone = (_d = object.timezone) !== null && _d !== void 0 ? _d : "";
        message.capacity = (_e = object.capacity) !== null && _e !== void 0 ? _e : 0;
        message.dayOfWeek = (_f = object.dayOfWeek) !== null && _f !== void 0 ? _f : 0;
        message.startTime = (_g = object.startTime) !== null && _g !== void 0 ? _g : "";
        message.endTime = (_h = object.endTime) !== null && _h !== void 0 ? _h : "";
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
