const StorageKey = {
  USER: 'afterhero_user',
  CURRENTSTEP: 'afterhero_step',
};

function setLocaleStroage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function removeLocaleStroage(key) {
  localStorage.removeItem(key);
}

function getLocaleStroage(key) {
  let obj = localStorage.getItem(key);
  return JSON.parse(obj);
}

export { StorageKey, setLocaleStroage, getLocaleStroage, removeLocaleStroage };
