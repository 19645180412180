"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CourseDiscussion = exports.courseDiscussion_StatusToJSON = exports.courseDiscussion_StatusFromJSON = exports.CourseDiscussion_Status = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
var message_1 = require("./message");
var photo_1 = require("./photo");
var Long = require("long");
exports.protobufPackage = "com.afterschool.hero";
var CourseDiscussion_Status;
(function (CourseDiscussion_Status) {
    CourseDiscussion_Status[CourseDiscussion_Status["APPLICATION_STATUS_UNKNOWN"] = 0] = "APPLICATION_STATUS_UNKNOWN";
    CourseDiscussion_Status[CourseDiscussion_Status["APPLICATION_STATUS_INVITED"] = 1] = "APPLICATION_STATUS_INVITED";
    CourseDiscussion_Status[CourseDiscussion_Status["APPLICATION_STATUS_CONFIRMED"] = 2] = "APPLICATION_STATUS_CONFIRMED";
    CourseDiscussion_Status[CourseDiscussion_Status["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(CourseDiscussion_Status || (exports.CourseDiscussion_Status = CourseDiscussion_Status = {}));
function courseDiscussion_StatusFromJSON(object) {
    switch (object) {
        case 0:
        case "APPLICATION_STATUS_UNKNOWN":
            return CourseDiscussion_Status.APPLICATION_STATUS_UNKNOWN;
        case 1:
        case "APPLICATION_STATUS_INVITED":
            return CourseDiscussion_Status.APPLICATION_STATUS_INVITED;
        case 2:
        case "APPLICATION_STATUS_CONFIRMED":
            return CourseDiscussion_Status.APPLICATION_STATUS_CONFIRMED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return CourseDiscussion_Status.UNRECOGNIZED;
    }
}
exports.courseDiscussion_StatusFromJSON = courseDiscussion_StatusFromJSON;
function courseDiscussion_StatusToJSON(object) {
    switch (object) {
        case CourseDiscussion_Status.APPLICATION_STATUS_UNKNOWN:
            return "APPLICATION_STATUS_UNKNOWN";
        case CourseDiscussion_Status.APPLICATION_STATUS_INVITED:
            return "APPLICATION_STATUS_INVITED";
        case CourseDiscussion_Status.APPLICATION_STATUS_CONFIRMED:
            return "APPLICATION_STATUS_CONFIRMED";
        case CourseDiscussion_Status.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.courseDiscussion_StatusToJSON = courseDiscussion_StatusToJSON;
function createBaseCourseDiscussion() {
    return {
        id: "",
        courseId: "",
        courseCover: undefined,
        courseTitle: "",
        studentId: "",
        studentName: "",
        studentThumbnail: undefined,
        teacherId: "",
        teacherName: "",
        teacherThumbnail: undefined,
        messagesIds: [],
        lastMessage: undefined,
        lastMessageTimestampMs: 0,
    };
}
exports.CourseDiscussion = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.courseId !== "") {
            writer.uint32(42).string(message.courseId);
        }
        if (message.courseCover !== undefined) {
            photo_1.Photo.encode(message.courseCover, writer.uint32(50).fork()).ldelim();
        }
        if (message.courseTitle !== "") {
            writer.uint32(138).string(message.courseTitle);
        }
        if (message.studentId !== "") {
            writer.uint32(74).string(message.studentId);
        }
        if (message.studentName !== "") {
            writer.uint32(82).string(message.studentName);
        }
        if (message.studentThumbnail !== undefined) {
            photo_1.Photo.encode(message.studentThumbnail, writer.uint32(90).fork()).ldelim();
        }
        if (message.teacherId !== "") {
            writer.uint32(98).string(message.teacherId);
        }
        if (message.teacherName !== "") {
            writer.uint32(130).string(message.teacherName);
        }
        if (message.teacherThumbnail !== undefined) {
            photo_1.Photo.encode(message.teacherThumbnail, writer.uint32(106).fork()).ldelim();
        }
        for (var _i = 0, _a = message.messagesIds; _i < _a.length; _i++) {
            var v = _a[_i];
            writer.uint32(114).string(v);
        }
        if (message.lastMessage !== undefined) {
            message_1.Message.encode(message.lastMessage, writer.uint32(122).fork()).ldelim();
        }
        if (message.lastMessageTimestampMs !== 0) {
            writer.uint32(144).int64(message.lastMessageTimestampMs);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseCourseDiscussion();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.courseId = reader.string();
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.courseCover = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 17:
                    if (tag !== 138) {
                        break;
                    }
                    message.courseTitle = reader.string();
                    continue;
                case 9:
                    if (tag !== 74) {
                        break;
                    }
                    message.studentId = reader.string();
                    continue;
                case 10:
                    if (tag !== 82) {
                        break;
                    }
                    message.studentName = reader.string();
                    continue;
                case 11:
                    if (tag !== 90) {
                        break;
                    }
                    message.studentThumbnail = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 12:
                    if (tag !== 98) {
                        break;
                    }
                    message.teacherId = reader.string();
                    continue;
                case 16:
                    if (tag !== 130) {
                        break;
                    }
                    message.teacherName = reader.string();
                    continue;
                case 13:
                    if (tag !== 106) {
                        break;
                    }
                    message.teacherThumbnail = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 14:
                    if (tag !== 114) {
                        break;
                    }
                    message.messagesIds.push(reader.string());
                    continue;
                case 15:
                    if (tag !== 122) {
                        break;
                    }
                    message.lastMessage = message_1.Message.decode(reader, reader.uint32());
                    continue;
                case 18:
                    if (tag !== 144) {
                        break;
                    }
                    message.lastMessageTimestampMs = longToNumber(reader.int64());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            courseId: isSet(object.courseId) ? String(object.courseId) : "",
            courseCover: isSet(object.courseCover) ? photo_1.Photo.fromJSON(object.courseCover) : undefined,
            courseTitle: isSet(object.courseTitle) ? String(object.courseTitle) : "",
            studentId: isSet(object.studentId) ? String(object.studentId) : "",
            studentName: isSet(object.studentName) ? String(object.studentName) : "",
            studentThumbnail: isSet(object.studentThumbnail) ? photo_1.Photo.fromJSON(object.studentThumbnail) : undefined,
            teacherId: isSet(object.teacherId) ? String(object.teacherId) : "",
            teacherName: isSet(object.teacherName) ? String(object.teacherName) : "",
            teacherThumbnail: isSet(object.teacherThumbnail) ? photo_1.Photo.fromJSON(object.teacherThumbnail) : undefined,
            messagesIds: Array.isArray(object === null || object === void 0 ? void 0 : object.messagesIds) ? object.messagesIds.map(function (e) { return String(e); }) : [],
            lastMessage: isSet(object.lastMessage) ? message_1.Message.fromJSON(object.lastMessage) : undefined,
            lastMessageTimestampMs: isSet(object.lastMessageTimestampMs) ? Number(object.lastMessageTimestampMs) : 0,
        };
    },
    toJSON: function (message) {
        var _a;
        var obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.courseId !== "") {
            obj.courseId = message.courseId;
        }
        if (message.courseCover !== undefined) {
            obj.courseCover = photo_1.Photo.toJSON(message.courseCover);
        }
        if (message.courseTitle !== "") {
            obj.courseTitle = message.courseTitle;
        }
        if (message.studentId !== "") {
            obj.studentId = message.studentId;
        }
        if (message.studentName !== "") {
            obj.studentName = message.studentName;
        }
        if (message.studentThumbnail !== undefined) {
            obj.studentThumbnail = photo_1.Photo.toJSON(message.studentThumbnail);
        }
        if (message.teacherId !== "") {
            obj.teacherId = message.teacherId;
        }
        if (message.teacherName !== "") {
            obj.teacherName = message.teacherName;
        }
        if (message.teacherThumbnail !== undefined) {
            obj.teacherThumbnail = photo_1.Photo.toJSON(message.teacherThumbnail);
        }
        if ((_a = message.messagesIds) === null || _a === void 0 ? void 0 : _a.length) {
            obj.messagesIds = message.messagesIds;
        }
        if (message.lastMessage !== undefined) {
            obj.lastMessage = message_1.Message.toJSON(message.lastMessage);
        }
        if (message.lastMessageTimestampMs !== 0) {
            obj.lastMessageTimestampMs = Math.round(message.lastMessageTimestampMs);
        }
        return obj;
    },
    create: function (base) {
        return exports.CourseDiscussion.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var message = createBaseCourseDiscussion();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.courseId = (_b = object.courseId) !== null && _b !== void 0 ? _b : "";
        message.courseCover = (object.courseCover !== undefined && object.courseCover !== null)
            ? photo_1.Photo.fromPartial(object.courseCover)
            : undefined;
        message.courseTitle = (_c = object.courseTitle) !== null && _c !== void 0 ? _c : "";
        message.studentId = (_d = object.studentId) !== null && _d !== void 0 ? _d : "";
        message.studentName = (_e = object.studentName) !== null && _e !== void 0 ? _e : "";
        message.studentThumbnail = (object.studentThumbnail !== undefined && object.studentThumbnail !== null)
            ? photo_1.Photo.fromPartial(object.studentThumbnail)
            : undefined;
        message.teacherId = (_f = object.teacherId) !== null && _f !== void 0 ? _f : "";
        message.teacherName = (_g = object.teacherName) !== null && _g !== void 0 ? _g : "";
        message.teacherThumbnail = (object.teacherThumbnail !== undefined && object.teacherThumbnail !== null)
            ? photo_1.Photo.fromPartial(object.teacherThumbnail)
            : undefined;
        message.messagesIds = ((_h = object.messagesIds) === null || _h === void 0 ? void 0 : _h.map(function (e) { return e; })) || [];
        message.lastMessage = (object.lastMessage !== undefined && object.lastMessage !== null)
            ? message_1.Message.fromPartial(object.lastMessage)
            : undefined;
        message.lastMessageTimestampMs = (_j = object.lastMessageTimestampMs) !== null && _j !== void 0 ? _j : 0;
        return message;
    },
};
var tsProtoGlobalThis = (function () {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToNumber(long) {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
