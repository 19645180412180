<!--  -->
<template>
  <div class="class-introduce boxH">
    <div class="introduce-space">
      <div class="introduce-header boxH cr-center jc-between">
        <div class="class-name">{{ courseDetail.title }}</div>
        <div class="boxH cr-center">
          <div class="class-mark">
            {{ courseDetail.rating == 0 ? '4.9 / 5' : courseDetail.rating }}
          </div>
          <img class="class-star" :src="iconPath.iconStar" />
        </div>
      </div>
      <div class="class-img-container mb-20">
        <BaseImage class="class-img" :photo="courseDetail.cover"></BaseImage>
      </div>
      <div class="class-content">{{ courseDescription }}</div>
      <div class="boxV">
        <div class="class-detail-item boxH cr-center">
          <div class="class-icon boxH jccr-center">
            <img class="class-icon-img" :src="iconPath.iconLabelOrange" />
          </div>
          <div class="class-detail-text">{{ courseType }}</div>
        </div>
        <div class="class-detail-item boxH cr-center">
          <div class="class-icon boxH jccr-center">
            <img class="class-icon-img" :src="iconPath.iconClock" />
          </div>
          <div class="class-detail-text">{{ classTime }}</div>
        </div>
        <div class="class-detail-item boxH cr-center">
          <div class="class-icon boxH jccr-center">
            <img class="class-icon-img" :src="iconPath.iconUser" />
          </div>
          <div class="class-detail-text">
            {{
              courseDetail.classTutor
                ? courseDetail.classTutor
                : 'Your Favoriate Teacher'
            }}
          </div>
        </div>
        <div class="class-detail-item boxH cr-center">
          <div class="class-icon boxH jccr-center">
            <img class="class-icon-img" :src="iconPath.iconLanguage" />
          </div>
          <div class="class-detail-text">{{ courseLanguage }}</div>
        </div>
      </div>
      <ReviewCard
        v-if="reviewList.length > 0"
        :reviewList="reviewList"
      ></ReviewCard>
    </div>
    <div class="boxV">
      <DownloadCard
        v-if="showAttendSpace"
        :download="downloadObj"
        @submitQuestion="submitQuestion"
      ></DownloadCard>
      <GalleryCard
        class="mt-20"
        @setGalleryVisible="setGalleryVisible"
        v-if="courseDetail.galleryPhotos?.length > 0"
        :galleryPhotos="courseDetail.galleryPhotos"
      >
      </GalleryCard>
    </div>
  </div>
  <el-dialog :append-to-body="true" v-model="adjustVisible" width="30%" center>
    <template #header>
      <div class="dialog-title">Can't Attend? Let's Adjust</div>
    </template>
    <template #footer>
      <div class="boxV jccr-center">
        <BaseButton
          type="fill"
          @click="reschedule"
          :title="t('reschedule')"
        ></BaseButton>
        <BaseButton
          class="mt-20"
          type="linear"
          @click="adjustVisible = false"
          :title="t('cancel')"
        ></BaseButton>
      </div>
    </template>
  </el-dialog>
  <el-dialog
    v-model="gallerySlideShowVisible"
    :append-to-body="true"
    fullscreen
    center
  >
    <template #header>
      <div class="dialog-title">Gallery images</div>
    </template>
    <template #footer>
      <el-carousel
        class="carousel-container"
        height="50rem"
        :autoplay="false"
        :initial-index="0"
        arrow="always"
      >
        <el-carousel-item
          v-for="(item, index) in gallerySlideShow"
          :key="index"
        >
          <BaseImage class="slide-show-image" :photo="item"></BaseImage>
        </el-carousel-item>
      </el-carousel>
    </template>
  </el-dialog>
</template>

<script setup>
import GalleryCard from './GalleryCard.vue';
import DownloadCard from './DownloadCard.vue';
import ReviewCard from './ReviewCard.vue';

import iconPath from '@/assets/icons';
import { languageOptionsObj, classTypeOptionsObj } from '@/utils/options';
import { getFormattedTimeFromTimestampMs } from '@/utils';

import { courseRef, reviewRef, safeReadRef } from '@/api';
import { getDoc, doc, query, where, getDocs } from 'firebase/firestore';

import { ElLoading } from 'element-plus';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { useI18n } from 'vue-i18n';
import {useStore} from "vuex";
import { typeToJSON } from '@/api/protos/course';
import moment from 'moment';

/************************************************ 初始化数据 ************************************************/
const { t } = useI18n();
const store = useStore();

const router = useRouter();
const props = defineProps({
  courseId: String,
  classStartTimestampMs: Number,
  classEndTimestampMs: Number,
  showAttendSpace: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: 'default',
  },
  course: {
    type: Object,
    default: () => ({}),
  },
});

const adjustVisible = ref(false);
const gallerySlideShowVisible = ref(false);
const gallerySlideShow = ref([]);

const downloadObj = reactive({
  title: 'Follow these steps to attend class',
  content: `· Download the required software<br>
    · Login (Create account if you do not have one)<br>
    · Click start class<br>
    · Do some assignments or quiz related to the subject`,
  downloadUrl: '',
  question: '',
});

const courseDetail = ref({});
const reviewList = ref([]);
const courseLanguage = computed(() => {
  if (!courseDetail.value.id) return '';
  let list = [];
  courseDetail.value.language.forEach((item) => {
    let language = languageOptionsObj[item];
    if (language) {
      list.push(language.title);
    }
  });
  return list.join(', ');
});

const courseType = computed(() => {
  const text = typeToJSON(courseDetail.value.type)
  return text.replace(/_/g, ' ');
})

const courseDescription = computed(() => {
  if (courseDetail.value) {
    if (store.state.user && store.state.user.languagePreference === 0) {
      if (courseDetail.value && courseDetail.value.description) {
        return courseDetail.value.description;
      }

      return courseDetail.value.descriptionChinese;
    } else if (store.state.user && store.state.user.languagePreference === 1) {
      if (courseDetail.value && courseDetail.value.descriptionChinese) {
        return courseDetail.value.descriptionChinese;
      }

      return courseDetail.value.description;
    }
    return null;
  }

  return null;
});

const classTime = computed(() => {
  if (props.type === 'default') {
    const startTime = getFormattedTimeFromTimestampMs(props.classStartTimestampMs, store.state.studentAvail.timezone, true).split(', ');
    const endTime = getFormattedTimeFromTimestampMs(props.classEndTimestampMs, store.state.studentAvail.timezone, true).split(', ')
    if(startTime[0] === endTime[0]){
      return `${startTime[0]}, ${startTime[1]} - ${endTime[1]}`;
    }
  
    return `${startTime[0]}, ${startTime[1]} -  ${endTime[0]}, ${endTime[1]}`;
  } else {
    const startWeekDate = moment().startOf('week')
    if (props.course.dayOfWeek > 0) {
      startWeekDate.add(props.course.dayOfWeek + 1, 'days');
    }
    const startTime = getFormattedTimeFromTimestampMs(startWeekDate.valueOf(), store.state.studentAvail.timezone, true).split(', ');
    const endTime = getFormattedTimeFromTimestampMs(startWeekDate.valueOf(), store.state.studentAvail.timezone, true).split(', ')

    if(startTime[0] === endTime[0]){
      return `${startTime[0]}, ${props.course.startTime} - ${props.course.endTime}`;
    }

    return `${startTime[0]}, ${props.course.startTime} -  ${endTime[0]}, ${props.course.endTime}`;
  }
})

const courseId = computed(() => props.courseId);
watch(courseId, (newVal, oldVal) => {
  console.log('courseId chanaged', newVal, oldVal);
  if (courseDetail.value.id != newVal) {
    requestGetCourse();
  }
});
/************************************************  生命周期  ************************************************/
onMounted(() => {
  console.log('classIntroduce onMounted');
  requestGetCourse();
});
/************************************************  点击事件  ************************************************/
function reschedule() {
  if(courseDetail.value && courseDetail.value.id){
    adjustVisible.value = false;
    router.push({ name: 'reschedule', state: { courseId: courseDetail.value.id } });
  }
}
function submitQuestion() {
  adjustVisible.value = true;
}

function setGalleryVisible(photo) {
  gallerySlideShow.value = [];
  gallerySlideShowVisible.value = true;

  const galleryPhotos = [...courseDetail.value.galleryPhotos];

  const index = galleryPhotos.findIndex((obj) => obj.url === photo.url);

  if (index > -1) {
    galleryPhotos.splice(index, 1);
    galleryPhotos.unshift(photo);
    gallerySlideShow.value = galleryPhotos;
  }
}

/************************************************  数据请求  ************************************************/
async function requestGetCourse() {
  const loading = ElLoading.service({
    lock: true,
    text: 'Loading...',
    background: 'rgba(0, 0, 0, 0.7)',
  });
  const docSnap = await getDoc(
    doc(courseRef, props.courseId ? props.courseId : ''),
  );
  if (docSnap.exists()) {
    courseDetail.value = docSnap.data();
    console.log('courseDetail.value -->', courseDetail.value)
    downloadObj.downloadUrl = courseDetail.value.downloadUrl || '';

    if (store.state.user && store.state.user.languagePreference === 0) {
      if (courseDetail.value && courseDetail.value.instruction) {
        downloadObj.content = courseDetail.value.instruction;
      } else if (courseDetail.value && courseDetail.value.instructionChinese) {
        downloadObj.content = courseDetail.value.instructionChinese;
      }
    } else if (store.state.user && store.state.user.languagePreference === 1) {
      if (courseDetail.value && courseDetail.value.instructionChinese) {
        downloadObj.content = courseDetail.value.instructionChinese;
      } else if (courseDetail.value && courseDetail.value.instruction) {
        downloadObj.content = courseDetail.value.instruction;
      }
    }

    requestGetReviews();
  }
  loading.close();
}

function requestGetReviews() {
  const reviewQuery = query(
    safeReadRef(reviewRef),
    where('courseId', '==', props.courseId),
  );
  getDocs(reviewQuery).then((querySnapshot) => {
    reviewList.value = querySnapshot.docs.map((doc) => doc.data());
    // console.log('reviews is', props.courseId, reviewList.value)
  });
}

/************************************************  辅助方法  ************************************************/
</script>
<style lang="scss" scoped>
.class-introduce {
  overflow: auto;
  width: 100%;
  height: 45rem;
  padding: 1.56rem;
  border-radius: 0.625rem;
  justify-content: center;
  gap: 5rem;
  border: 1px solid var(--foundation-grey-grey-100, #bebebf);

  .introduce-space {
    width: 38rem;
    margin-right: 1.88rem;
    font-size: 1rem;
    font-weight: 500;

    .introduce-header,
    .class-img,
    .class-content {
      margin-bottom: 1.25rem;
      font-size: medium;
    }

    .introduce-header {
      .class-name {
        color: var(--foundation-grey-grey-500, #2c2e30);
        font-size: 1.3125rem;
        font-weight: 700;
      }

      .class-mark {
        color: var(--foundation-grey-grey-500, #2c2e30);
        margin-left: 0.31rem;
      }
    }

    .class-star,
    .class-icon-img {
      width: 1.5rem;
      height: 1.5rem;
    }

    .class-detail-item {
      margin-bottom: 0.94rem;

      .class-icon {
        margin-right: 0.63rem;
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 0.625rem;
        background: var(--foundation-orange-orange-100, #ffe1be);
      }

      .class-detail-text {
        color: var(--foundation-grey-grey-500, #2c2e30);
        font-size: medium;
        font-weight: 700;
      }
    }

    .class-img-container {
      max-width: 100%;
      max-height: 13.5625rem;
      overflow: hidden;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.625rem;
    }

    .class-img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

.dialog-title {
  color: var(--foundation-orange-orange-500, #ff9e2c);
  text-align: center;
  font-family: Poppins;
  font-size: 21px;
  font-weight: 500;
}

.carousel-container {
  background-color: var(--foundation-grey-grey-50, #eaeaea);

  .slide-show-image {
    width: auto;
    height: 100%;
  }
}
</style>
