"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentAvail_AvailsEntry = exports.StudentAvail = exports.DailyAvail = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
exports.protobufPackage = "com.afterschool.hero";
function createBaseDailyAvail() {
    return { hour: [] };
}
exports.DailyAvail = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        writer.uint32(10).fork();
        for (var _i = 0, _a = message.hour; _i < _a.length; _i++) {
            var v = _a[_i];
            writer.int32(v);
        }
        writer.ldelim();
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseDailyAvail();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag === 8) {
                        message.hour.push(reader.int32());
                        continue;
                    }
                    if (tag === 10) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.hour.push(reader.int32());
                        }
                        continue;
                    }
                    break;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return { hour: Array.isArray(object === null || object === void 0 ? void 0 : object.hour) ? object.hour.map(function (e) { return Number(e); }) : [] };
    },
    toJSON: function (message) {
        var _a;
        var obj = {};
        if ((_a = message.hour) === null || _a === void 0 ? void 0 : _a.length) {
            obj.hour = message.hour.map(function (e) { return Math.round(e); });
        }
        return obj;
    },
    create: function (base) {
        return exports.DailyAvail.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a;
        var message = createBaseDailyAvail();
        message.hour = ((_a = object.hour) === null || _a === void 0 ? void 0 : _a.map(function (e) { return e; })) || [];
        return message;
    },
};
function createBaseStudentAvail() {
    return { userId: "", schoolEndHour: 0, timezone: "", avails: {} };
}
exports.StudentAvail = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.userId !== "") {
            writer.uint32(10).string(message.userId);
        }
        if (message.schoolEndHour !== 0) {
            writer.uint32(16).int32(message.schoolEndHour);
        }
        if (message.timezone !== "") {
            writer.uint32(34).string(message.timezone);
        }
        Object.entries(message.avails).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            exports.StudentAvail_AvailsEntry.encode({ key: key, value: value }, writer.uint32(26).fork()).ldelim();
        });
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseStudentAvail();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.userId = reader.string();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.schoolEndHour = reader.int32();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.timezone = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    var entry3 = exports.StudentAvail_AvailsEntry.decode(reader, reader.uint32());
                    if (entry3.value !== undefined) {
                        message.avails[entry3.key] = entry3.value;
                    }
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            userId: isSet(object.userId) ? String(object.userId) : "",
            schoolEndHour: isSet(object.schoolEndHour) ? Number(object.schoolEndHour) : 0,
            timezone: isSet(object.timezone) ? String(object.timezone) : "",
            avails: isObject(object.avails)
                ? Object.entries(object.avails).reduce(function (acc, _a) {
                    var key = _a[0], value = _a[1];
                    acc[Number(key)] = exports.DailyAvail.fromJSON(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.userId !== "") {
            obj.userId = message.userId;
        }
        if (message.schoolEndHour !== 0) {
            obj.schoolEndHour = Math.round(message.schoolEndHour);
        }
        if (message.timezone !== "") {
            obj.timezone = message.timezone;
        }
        if (message.avails) {
            var entries = Object.entries(message.avails);
            if (entries.length > 0) {
                obj.avails = {};
                entries.forEach(function (_a) {
                    var k = _a[0], v = _a[1];
                    obj.avails[k] = exports.DailyAvail.toJSON(v);
                });
            }
        }
        return obj;
    },
    create: function (base) {
        return exports.StudentAvail.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d;
        var message = createBaseStudentAvail();
        message.userId = (_a = object.userId) !== null && _a !== void 0 ? _a : "";
        message.schoolEndHour = (_b = object.schoolEndHour) !== null && _b !== void 0 ? _b : 0;
        message.timezone = (_c = object.timezone) !== null && _c !== void 0 ? _c : "";
        message.avails = Object.entries((_d = object.avails) !== null && _d !== void 0 ? _d : {}).reduce(function (acc, _a) {
            var key = _a[0], value = _a[1];
            if (value !== undefined) {
                acc[Number(key)] = exports.DailyAvail.fromPartial(value);
            }
            return acc;
        }, {});
        return message;
    },
};
function createBaseStudentAvail_AvailsEntry() {
    return { key: 0, value: undefined };
}
exports.StudentAvail_AvailsEntry = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.key !== 0) {
            writer.uint32(8).int32(message.key);
        }
        if (message.value !== undefined) {
            exports.DailyAvail.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseStudentAvail_AvailsEntry();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.key = reader.int32();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.value = exports.DailyAvail.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            key: isSet(object.key) ? Number(object.key) : 0,
            value: isSet(object.value) ? exports.DailyAvail.fromJSON(object.value) : undefined,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.key !== 0) {
            obj.key = Math.round(message.key);
        }
        if (message.value !== undefined) {
            obj.value = exports.DailyAvail.toJSON(message.value);
        }
        return obj;
    },
    create: function (base) {
        return exports.StudentAvail_AvailsEntry.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a;
        var message = createBaseStudentAvail_AvailsEntry();
        message.key = (_a = object.key) !== null && _a !== void 0 ? _a : 0;
        message.value = (object.value !== undefined && object.value !== null)
            ? exports.DailyAvail.fromPartial(object.value)
            : undefined;
        return message;
    },
};
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
