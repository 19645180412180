<!--  -->
<template>
  <div class="class-item boxV jccr-center" :style="style" @click="selectClassItem">
    <slot name="content"></slot>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import momentTimezone from 'moment-timezone';
import { useStore } from 'vuex';

/************************************************ 初始化数据 ************************************************/
const props = defineProps({
  course: Object,
  startHour: Number,
  computedWay: {
    //  timestamp or format
    type: String,
    default: 'timestamp',
  },
  size: {
    type: Object,
    default: () => ({
      width: 100,
      height: 80,
    }),
  },
});

const store = useStore();

const style = computed(() => {
  if (props.course?.style?.length > 0) return props.course.style;

  let course = props.course;
  let dayOfWeek, startMinute, endMinute;
  if (props.computedWay == 'timestamp') {
    if(store.state.user.isTeacherAdmin || (store.state.user && store.state.user.city && store.state.user.city.timezone)){

      // Convert timestamps to zoned times in the specified time zone
      const timezone = store.state.user.isTeacherAdmin ? "Asia/Shanghai" : store.state.user.city.timezone;
      ({ dayOfWeek, startMinute, endMinute } = computedByTimestampWithTimeZone(
        course.startTimestampMs,
        course.endTimestampMs,
          timezone
      ));
    }
  } else if (props.computedWay == 'format') {
    ({ startMinute, endMinute } = computedByStringFormat(
      course.startTime,
      course.endTime,
    ));
    dayOfWeek = course.dayOfWeek;
  }

  let top = (startMinute / 60) * 80;
  let height = ((endMinute - startMinute) / 60) * props.size.height;
  let left = (dayOfWeek % 7) * props.size.width;

  return `top: ${top}px; height: ${height}px; left: ${left}px;`;
});

const emit = defineEmits(['selectClassItem']);

/************************************************  生命周期  ************************************************/
/************************************************  数据请求  ************************************************/
/************************************************  点击事件  ************************************************/
function selectClassItem() {
  emit('selectClassItem', props.course);
}
/************************************************  回调事件  ************************************************/
/************************************************  辅助方法  ************************************************/
function computedByTimestamp(startTimestampMs, endTimestampMs) {
  let startDate = new Date(startTimestampMs);
  let endDate = new Date(endTimestampMs);
  let startMinute =
    (startDate.getHours() - props.startHour) * 60 + startDate.getMinutes();
  let endMinute =
    (endDate.getHours() - props.startHour) * 60 + endDate.getMinutes();
  let dayOfWeek = startDate.getDay();
  return { dayOfWeek, startMinute, endMinute };
}

function computedByTimestampWithTimeZone(startTimestampMs, endTimestampMs, timezone) {
  const startHour = props.startHour;

  const startDate = momentTimezone.tz(startTimestampMs, timezone);
  const endDate = momentTimezone.tz(endTimestampMs, timezone);

  // Calculate the day of the week (0 for Sunday, 1 for Monday, etc.)
  const dayOfWeek = startDate.day();

  // Calculate start and end minutes
  const startMinute = (startDate.hour() * 60 + startDate.minute()) - (startHour * 60);
  const endMinute = (endDate.hour() * 60 + endDate.minute()) - (startHour * 60);

  return { dayOfWeek, startMinute, endMinute };
}

function computedByStringFormat(startTime, endTime) {
  let startMinute =
    computedMiniteByTimeFormat(startTime) - props.startHour * 60;
  let endMinute = computedMiniteByTimeFormat(endTime) - props.startHour * 60;
  return { startMinute, endMinute };
}

/**
 * HH:mm
 * @param {*} timeFormat
 */
function computedMiniteByTimeFormat(timeFormat) {
  let time = timeFormat.split(':').map((str) => parseInt(str));
  return time[0] * 60 + time[1];
}
</script>
<style lang="scss" scoped>
.class-item {
  position: absolute;
  width: 100px;
  border-radius: 0.3125rem;
  border: 1px solid #000000;
}

.class-item:hover {
  z-index: 10;
}
</style>
