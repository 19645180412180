export default {
  teacher: {
    addNewCourse: '添加新课程',
    courseDetail: '课程详情',
    weeklyCalendar: '每周课表（北京时间)',
    saveAndNext: '保存并下一步',
    studentOnlyClass: '仅显示 有学生的',

    class: {
      unscheduledStudents: '未排课学生列表',
      localTime: '您的本地时间',
      scheduledStudents: '已排课',
    }
  },
  welcome: {
    title: '探索顶级儿童活动',
    subTitle: '和虚拟课程',
    becomeAfterSchoolHero: '通过以下5步',
    memberInSteps: '成为 After School Hero成员',
    letsGo: '让我们开始吧',
  },
  navigationBarItem: {
    dashboard: '课程安排',
    calendar: '课程表',
    inbox: '聊天',
  },
  signIn: '登 录',
  signOut: '登 出',
  started: '开 始',
  signInLong: '进入您的账号',
  phoneNumber: '输入您的手机号',
  verifyPhone: '手机验证登陆',
  codeSent: '请查看发送的验证码',
  resend: '重新发送',
  verify: '确认',
  dashboard: {
    message: '聊天',
    todayAgenda: '今天的日程',
    agenda: '日程',
    questionnaire: '课前问卷',
    rate: '评价',
    pastClasses: '已完成课程',
  },
  classDetail: {
    oneToOne: "1-对-1",
    oneToMany: "小班授课",
    aiClass: "人工智能课程",
    videoClass: "录播课",
    unknown: "未知授课模式"
  },
  image: '图片',
  video: '视屏',
  reschedule: '改期',
  cancel: '取消'
};
