<!--  -->
<template>
  <div class="review-card boxV">
    <div class="review-title mb-10">Reviews</div>
    <div class="review-content mb-15 boxV" v-for="review in displayedReviews" :key="review.id">
      <div class="boxH">
        <BaseAvatar class="student-avatar mr-10" :photo="review.studentThumbnail"></BaseAvatar>
        <div class="boxV jc-between">
          <div class="review-student-name">{{ review.studentName || '' }}</div>
          <el-rate v-model="review.courseRate" :colors="colors" allow-half disabled />
        </div>
      </div>
      <div class="review-desc mt-15" v-if="review.description.length > 0">{{ review.description }}</div>
    </div>
    <div v-if="displayedReviews.length < reviewList.length">
      <span @click="loadMoreReviews" class="load-more-button">Show more</span>
    </div>
  </div>
</template>

<script setup>

import { ref, computed } from 'vue'
/************************************************ 初始化数据 ************************************************/
const colors = ref(['#99A9BF', '#F7BA2A', '#FF9E2C'])

const props = defineProps({
  reviewList: String
})

const allReviewsLoaded = ref(false)
const displayedReviews = computed(() => {
  if (!props.reviewList) return []
  let count = allReviewsLoaded.value ? props.reviewList.length : 3
  let reviews = props.reviewList.slice(0, count)
  return reviews
})

/************************************************  生命周期  ************************************************/
/************************************************  数据请求  ************************************************/
/************************************************  点击事件  ************************************************/
function loadMoreReviews() {
  allReviewsLoaded.value = true
}
/************************************************  回调事件  ************************************************/
/************************************************  辅助方法  ************************************************/
</script>
<style lang="scss" scoped>
.review-card {
  width: 100%;
  padding: 1.25rem;
  border-radius: 0.625rem;
  border: 1px solid var(--foundation-grey-grey-100, #bebebf);
  color: var(--foundation-grey-grey-500, #2c2e30);
  font-size: 1rem;
  font-weight: 400;

  .review-title {
    color: var(--foundation-grey-grey-500, #2C2E30);
    font-size: 1.3125rem;
    font-weight: 500;
  }

  .review-content {
    width: 100%;
    padding: 0.9375rem;
    border-radius: 0.625rem;
    border: 1px solid var(--foundation-grey-grey-50, #EAEAEA);

    .review-student-name {
      color: var(--foundation-grey-grey-500, #2C2E30);
      font-size: 1rem;
      font-weight: 500;
    }

    .student-avatar {
      width: 3.5rem;
      height: 3.5rem;
    }

    .review-desc {
      color: var(--foundation-grey-grey-500, #2C2E30);
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .load-more-button {
    cursor: pointer;
    font-size: 1.3125rem;
    font-weight: 500;
    color: var(--foundation-orange-orange-500, #ff9e2c);
    text-decoration: underline;
  }
}
</style>