import bgPerfectStep1 from './bg_perfect_step1.png';
import bgPerfectStep2 from './bg_perfect_step2.png';
import bgPerfectStep3 from './bg_perfect_step3.png';
import bgPerfectStep4 from './bg_perfect_step4.png';
import bgPerfectStep5 from './bg_perfect_step4.png';
import bgPerfectInterest from './bg_perfect_interest.png';
import bgPerfectKidsphoto from './bg_perfect_kidsphoto.png';

import iconPerfectBoy from './icon_boy.png';
import iconPerfectGirl from './icon_girl.png';
import iconPerfectGirlActive from './icon_girl_active.png';
import iconPerfectShy from './icon_shy.png';
import iconPerfectOutgoing from './icon_outgoing.png';
import iconPerfectOutgoingActive from './icon_outgoing_active.png';
import iconPerfectShyActive from './icon_shy_active.png';
import iconPerfectBoyActive from './icon_boy_active.png'

import bgHome from './home_bg.png';
import bgMember from './member_bg.png';
import bgPaymentSuccess from './payment-success.png';

import logo from './logo.jpg'

export default {
  /** perfect page image */
  iconPerfectBoy,
  iconPerfectBoyActive,
  iconPerfectGirl,
  iconPerfectGirlActive,
  iconPerfectShy,
  iconPerfectShyActive,
  iconPerfectOutgoing,
  iconPerfectOutgoingActive,



  bgPerfectStep1,
  bgPerfectStep2,
  bgPerfectKidsphoto,
  bgPerfectStep3,
  bgPerfectStep4,
  bgPerfectStep5,
  bgPerfectInterest,

  bgHome,
  bgMember,
  bgPaymentSuccess,

  logo
};
