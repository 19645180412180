"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Questionnaire = exports.QuestionAndAnswer = exports.questionAndAnswer_QuestionTypeToJSON = exports.questionAndAnswer_QuestionTypeFromJSON = exports.QuestionAndAnswer_QuestionType = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
var photo_1 = require("./photo");
exports.protobufPackage = "com.afterschool.hero";
var QuestionAndAnswer_QuestionType;
(function (QuestionAndAnswer_QuestionType) {
    QuestionAndAnswer_QuestionType[QuestionAndAnswer_QuestionType["TYPE_UNKNOWN"] = 1] = "TYPE_UNKNOWN";
    QuestionAndAnswer_QuestionType[QuestionAndAnswer_QuestionType["TYPE_SHORT_ANSWER"] = 2] = "TYPE_SHORT_ANSWER";
    QuestionAndAnswer_QuestionType[QuestionAndAnswer_QuestionType["TYPE_MULTIPLE_CHOICE"] = 3] = "TYPE_MULTIPLE_CHOICE";
    QuestionAndAnswer_QuestionType[QuestionAndAnswer_QuestionType["TYPE_UPLOAD_IMAGE"] = 4] = "TYPE_UPLOAD_IMAGE";
    QuestionAndAnswer_QuestionType[QuestionAndAnswer_QuestionType["TYPE_UPLOAD_VIDEO"] = 5] = "TYPE_UPLOAD_VIDEO";
    QuestionAndAnswer_QuestionType[QuestionAndAnswer_QuestionType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(QuestionAndAnswer_QuestionType || (exports.QuestionAndAnswer_QuestionType = QuestionAndAnswer_QuestionType = {}));
function questionAndAnswer_QuestionTypeFromJSON(object) {
    switch (object) {
        case 1:
        case "TYPE_UNKNOWN":
            return QuestionAndAnswer_QuestionType.TYPE_UNKNOWN;
        case 2:
        case "TYPE_SHORT_ANSWER":
            return QuestionAndAnswer_QuestionType.TYPE_SHORT_ANSWER;
        case 3:
        case "TYPE_MULTIPLE_CHOICE":
            return QuestionAndAnswer_QuestionType.TYPE_MULTIPLE_CHOICE;
        case 4:
        case "TYPE_UPLOAD_IMAGE":
            return QuestionAndAnswer_QuestionType.TYPE_UPLOAD_IMAGE;
        case 5:
        case "TYPE_UPLOAD_VIDEO":
            return QuestionAndAnswer_QuestionType.TYPE_UPLOAD_VIDEO;
        case -1:
        case "UNRECOGNIZED":
        default:
            return QuestionAndAnswer_QuestionType.UNRECOGNIZED;
    }
}
exports.questionAndAnswer_QuestionTypeFromJSON = questionAndAnswer_QuestionTypeFromJSON;
function questionAndAnswer_QuestionTypeToJSON(object) {
    switch (object) {
        case QuestionAndAnswer_QuestionType.TYPE_UNKNOWN:
            return "TYPE_UNKNOWN";
        case QuestionAndAnswer_QuestionType.TYPE_SHORT_ANSWER:
            return "TYPE_SHORT_ANSWER";
        case QuestionAndAnswer_QuestionType.TYPE_MULTIPLE_CHOICE:
            return "TYPE_MULTIPLE_CHOICE";
        case QuestionAndAnswer_QuestionType.TYPE_UPLOAD_IMAGE:
            return "TYPE_UPLOAD_IMAGE";
        case QuestionAndAnswer_QuestionType.TYPE_UPLOAD_VIDEO:
            return "TYPE_UPLOAD_VIDEO";
        case QuestionAndAnswer_QuestionType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.questionAndAnswer_QuestionTypeToJSON = questionAndAnswer_QuestionTypeToJSON;
function createBaseQuestionAndAnswer() {
    return {
        question: "",
        isRequired: false,
        questionType: 1,
        choices: [],
        text: undefined,
        image: undefined,
        video: undefined,
    };
}
exports.QuestionAndAnswer = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.question !== "") {
            writer.uint32(10).string(message.question);
        }
        if (message.isRequired === true) {
            writer.uint32(32).bool(message.isRequired);
        }
        if (message.questionType !== 1) {
            writer.uint32(16).int32(message.questionType);
        }
        for (var _i = 0, _a = message.choices; _i < _a.length; _i++) {
            var v = _a[_i];
            writer.uint32(26).string(v);
        }
        if (message.text !== undefined) {
            writer.uint32(114).string(message.text);
        }
        if (message.image !== undefined) {
            photo_1.Photo.encode(message.image, writer.uint32(122).fork()).ldelim();
        }
        if (message.video !== undefined) {
            photo_1.Photo.encode(message.video, writer.uint32(130).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseQuestionAndAnswer();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.question = reader.string();
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }
                    message.isRequired = reader.bool();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.questionType = reader.int32();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.choices.push(reader.string());
                    continue;
                case 14:
                    if (tag !== 114) {
                        break;
                    }
                    message.text = reader.string();
                    continue;
                case 15:
                    if (tag !== 122) {
                        break;
                    }
                    message.image = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 16:
                    if (tag !== 130) {
                        break;
                    }
                    message.video = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            question: isSet(object.question) ? String(object.question) : "",
            isRequired: isSet(object.isRequired) ? Boolean(object.isRequired) : false,
            questionType: isSet(object.questionType) ? questionAndAnswer_QuestionTypeFromJSON(object.questionType) : 1,
            choices: Array.isArray(object === null || object === void 0 ? void 0 : object.choices) ? object.choices.map(function (e) { return String(e); }) : [],
            text: isSet(object.text) ? String(object.text) : undefined,
            image: isSet(object.image) ? photo_1.Photo.fromJSON(object.image) : undefined,
            video: isSet(object.video) ? photo_1.Photo.fromJSON(object.video) : undefined,
        };
    },
    toJSON: function (message) {
        var _a;
        var obj = {};
        if (message.question !== "") {
            obj.question = message.question;
        }
        if (message.isRequired === true) {
            obj.isRequired = message.isRequired;
        }
        if (message.questionType !== 1) {
            obj.questionType = questionAndAnswer_QuestionTypeToJSON(message.questionType);
        }
        if ((_a = message.choices) === null || _a === void 0 ? void 0 : _a.length) {
            obj.choices = message.choices;
        }
        if (message.text !== undefined) {
            obj.text = message.text;
        }
        if (message.image !== undefined) {
            obj.image = photo_1.Photo.toJSON(message.image);
        }
        if (message.video !== undefined) {
            obj.video = photo_1.Photo.toJSON(message.video);
        }
        return obj;
    },
    create: function (base) {
        return exports.QuestionAndAnswer.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e;
        var message = createBaseQuestionAndAnswer();
        message.question = (_a = object.question) !== null && _a !== void 0 ? _a : "";
        message.isRequired = (_b = object.isRequired) !== null && _b !== void 0 ? _b : false;
        message.questionType = (_c = object.questionType) !== null && _c !== void 0 ? _c : 1;
        message.choices = ((_d = object.choices) === null || _d === void 0 ? void 0 : _d.map(function (e) { return e; })) || [];
        message.text = (_e = object.text) !== null && _e !== void 0 ? _e : undefined;
        message.image = (object.image !== undefined && object.image !== null) ? photo_1.Photo.fromPartial(object.image) : undefined;
        message.video = (object.video !== undefined && object.video !== null) ? photo_1.Photo.fromPartial(object.video) : undefined;
        return message;
    },
};
function createBaseQuestionnaire() {
    return { qna: [], courseId: "", studentId: "" };
}
exports.Questionnaire = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        for (var _i = 0, _a = message.qna; _i < _a.length; _i++) {
            var v = _a[_i];
            exports.QuestionAndAnswer.encode(v, writer.uint32(10).fork()).ldelim();
        }
        if (message.courseId !== "") {
            writer.uint32(18).string(message.courseId);
        }
        if (message.studentId !== "") {
            writer.uint32(26).string(message.studentId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseQuestionnaire();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.qna.push(exports.QuestionAndAnswer.decode(reader, reader.uint32()));
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.courseId = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.studentId = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            qna: Array.isArray(object === null || object === void 0 ? void 0 : object.qna) ? object.qna.map(function (e) { return exports.QuestionAndAnswer.fromJSON(e); }) : [],
            courseId: isSet(object.courseId) ? String(object.courseId) : "",
            studentId: isSet(object.studentId) ? String(object.studentId) : "",
        };
    },
    toJSON: function (message) {
        var _a;
        var obj = {};
        if ((_a = message.qna) === null || _a === void 0 ? void 0 : _a.length) {
            obj.qna = message.qna.map(function (e) { return exports.QuestionAndAnswer.toJSON(e); });
        }
        if (message.courseId !== "") {
            obj.courseId = message.courseId;
        }
        if (message.studentId !== "") {
            obj.studentId = message.studentId;
        }
        return obj;
    },
    create: function (base) {
        return exports.Questionnaire.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c;
        var message = createBaseQuestionnaire();
        message.qna = ((_a = object.qna) === null || _a === void 0 ? void 0 : _a.map(function (e) { return exports.QuestionAndAnswer.fromPartial(e); })) || [];
        message.courseId = (_b = object.courseId) !== null && _b !== void 0 ? _b : "";
        message.studentId = (_c = object.studentId) !== null && _c !== void 0 ? _c : "";
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
