import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyDDsTlcvba8Xxuro4lfZvp5-z4anErMLRI',
  authDomain: 'afterschool-her.firebaseapp.com',
  projectId: 'afterschool-her',
  storageBucket: 'afterschool-her.appspot.com',
  messagingSenderId: '584052154350',
  appId: '1:584052154350:web:f79a36810159a6127878ff',
  measurementId: 'G-48FN16D91C',
};
const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const functions = getFunctions(firebaseApp);
export default firebaseApp;
