import { City } from '@/api/protos/city';

console.log('api index');
import {
  getDoc,
  collection,
  getDocs,
  query,
  where,
  setDoc,
  doc,
  limit,
  startAt,
  onSnapshot,
  addDoc,
  or,
  getFirestore,
} from 'firebase/firestore';
import { Interest } from '@/api/protos/interest';
import { Class } from '@/api/protos/class';
import { StudentAvail } from './protos/student_avail';
import { ClassTeacherView } from './protos/class_teacher_view'

import { User } from '@/api/protos/user';
import { CourseDiscussion } from '@/api/protos/course_discussion';
import { Message, message_ActionTypeFromJSON } from '@/api/protos/message';
import { MagicCalendar } from './protos/magic_calendar';
import firebaseApp from './firebase';
import { QuestionAndAnswer, Questionnaire } from './protos/questionnaire';
import { Course } from './protos/course';
import { WeeklySession } from './protos/weekly_session';
import { Review } from '@/api/protos/review'
import { Teacher } from './protos/teacher';

export const db = getFirestore(firebaseApp);

export const safeReadRef = (ref) => {
  return query(ref, limit(100));
};

export const magicCalendarRef = collection(db, 'MagicCalendars').withConverter({
  fromFirestore(snapshot, options) {
    return MagicCalendar.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return MagicCalendar.toJSON(modelObject);
  },
});

export const classesRef = collection(db, 'Classes').withConverter({
  fromFirestore(snapshot, options) {
    return Class.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return Class.toJSON(modelObject);
  },
});

export const questionnaireResponsesRef = collection(
  db,
  'QuestionnaireResponses',
).withConverter({
  fromFirestore(snapshot, options) {
    return Questionnaire.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return Questionnaire.toJSON(modelObject);
  },
});

export const interestRef = collection(db, 'Interests').withConverter({
  fromFirestore(snapshot, options) {
    return Interest.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return Interest.toJSON(modelObject);
  },
});

export const courseDiscussionRef = collection(
  db,
  'CourseDiscussions',
).withConverter({
  fromFirestore(snapshot, options) {
    return CourseDiscussion.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return CourseDiscussion.toJSON(modelObject);
  },
});

export const messageRef = collection(db, 'Messages').withConverter({
  fromFirestore(snapshot, options) {
    return Message.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return Message.toJSON(modelObject);
  },
});

export const userRef = collection(db, 'Users').withConverter({
  fromFirestore(snapshot, options) {
    return User.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return User.toJSON(modelObject);
  },
});

export const studentAvailRef = collection(db, 'StudentAvails').withConverter({
  fromFirestore(snapshot, options) {
    return StudentAvail.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return StudentAvail.toJSON(modelObject);
  },
});

export const citiesRef = collection(db, 'Cities').withConverter({
  fromFirestore(snapshot, options) {
    return City.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return City.toJSON(modelObject);
  },
});

export const courseRef = collection(db, 'Courses').withConverter({
  fromFirestore(snapshot, options) {
    return Course.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return Course.toJSON(modelObject);
  },
});

export const weeklySessionRef = collection(db, 'WeeklySessions').withConverter({
  fromFirestore(snapshot, options) {
    return WeeklySession.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return WeeklySession.toJSON(modelObject);
  },
});

export const reviewRef = collection(db, 'Reviews').withConverter({
  fromFirestore(snapshot, options) {
    return Review.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return Review.toJSON(modelObject);
  },
});

export const classTeacherViewRef = collection(db, 'ClassTeacherViews').withConverter({
  fromFirestore(snapshot, options) {
    return ClassTeacherView.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return ClassTeacherView.toJSON(modelObject);
  }
});

export const teacherRef = collection(db, 'Teachers').withConverter({
  fromFirestore(snapshot, options) {
    return Teacher.fromJSON(snapshot.data(options));
  },
  toFirestore(modelObject) {
    return Teacher.toJSON(modelObject);
  }
});
