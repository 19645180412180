"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Message = exports.message_ActionTypeToJSON = exports.message_ActionTypeFromJSON = exports.Message_ActionType = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
var photo_1 = require("./photo");
var Long = require("long");
exports.protobufPackage = "com.afterschool.hero";
var Message_ActionType;
(function (Message_ActionType) {
    Message_ActionType[Message_ActionType["ACTION_TYPE_UNKNOWN"] = 0] = "ACTION_TYPE_UNKNOWN";
    Message_ActionType[Message_ActionType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Message_ActionType || (exports.Message_ActionType = Message_ActionType = {}));
function message_ActionTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "ACTION_TYPE_UNKNOWN":
            return Message_ActionType.ACTION_TYPE_UNKNOWN;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Message_ActionType.UNRECOGNIZED;
    }
}
exports.message_ActionTypeFromJSON = message_ActionTypeFromJSON;
function message_ActionTypeToJSON(object) {
    switch (object) {
        case Message_ActionType.ACTION_TYPE_UNKNOWN:
            return "ACTION_TYPE_UNKNOWN";
        case Message_ActionType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
exports.message_ActionTypeToJSON = message_ActionTypeToJSON;
function createBaseMessage() {
    return {
        id: "",
        courseDiscussionId: "",
        senderId: "",
        senderName: "",
        senderThumbnail: undefined,
        receiverId: "",
        receiverName: "",
        receiverThumbnail: undefined,
        courseCover: undefined,
        timestampMs: 0,
        readByReceiver: false,
        text: undefined,
        image: undefined,
        video: undefined,
        action: undefined,
    };
}
exports.Message = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.courseDiscussionId !== "") {
            writer.uint32(18).string(message.courseDiscussionId);
        }
        if (message.senderId !== "") {
            writer.uint32(26).string(message.senderId);
        }
        if (message.senderName !== "") {
            writer.uint32(34).string(message.senderName);
        }
        if (message.senderThumbnail !== undefined) {
            photo_1.Photo.encode(message.senderThumbnail, writer.uint32(42).fork()).ldelim();
        }
        if (message.receiverId !== "") {
            writer.uint32(50).string(message.receiverId);
        }
        if (message.receiverName !== "") {
            writer.uint32(58).string(message.receiverName);
        }
        if (message.receiverThumbnail !== undefined) {
            photo_1.Photo.encode(message.receiverThumbnail, writer.uint32(66).fork()).ldelim();
        }
        if (message.courseCover !== undefined) {
            photo_1.Photo.encode(message.courseCover, writer.uint32(146).fork()).ldelim();
        }
        if (message.timestampMs !== 0) {
            writer.uint32(72).int64(message.timestampMs);
        }
        if (message.readByReceiver === true) {
            writer.uint32(80).bool(message.readByReceiver);
        }
        if (message.text !== undefined) {
            writer.uint32(114).string(message.text);
        }
        if (message.image !== undefined) {
            photo_1.Photo.encode(message.image, writer.uint32(122).fork()).ldelim();
        }
        if (message.video !== undefined) {
            photo_1.Photo.encode(message.video, writer.uint32(130).fork()).ldelim();
        }
        if (message.action !== undefined) {
            writer.uint32(136).int32(message.action);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseMessage();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.courseDiscussionId = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.senderId = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.senderName = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.senderThumbnail = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.receiverId = reader.string();
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.receiverName = reader.string();
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.receiverThumbnail = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 18:
                    if (tag !== 146) {
                        break;
                    }
                    message.courseCover = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 9:
                    if (tag !== 72) {
                        break;
                    }
                    message.timestampMs = longToNumber(reader.int64());
                    continue;
                case 10:
                    if (tag !== 80) {
                        break;
                    }
                    message.readByReceiver = reader.bool();
                    continue;
                case 14:
                    if (tag !== 114) {
                        break;
                    }
                    message.text = reader.string();
                    continue;
                case 15:
                    if (tag !== 122) {
                        break;
                    }
                    message.image = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 16:
                    if (tag !== 130) {
                        break;
                    }
                    message.video = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
                case 17:
                    if (tag !== 136) {
                        break;
                    }
                    message.action = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            courseDiscussionId: isSet(object.courseDiscussionId) ? String(object.courseDiscussionId) : "",
            senderId: isSet(object.senderId) ? String(object.senderId) : "",
            senderName: isSet(object.senderName) ? String(object.senderName) : "",
            senderThumbnail: isSet(object.senderThumbnail) ? photo_1.Photo.fromJSON(object.senderThumbnail) : undefined,
            receiverId: isSet(object.receiverId) ? String(object.receiverId) : "",
            receiverName: isSet(object.receiverName) ? String(object.receiverName) : "",
            receiverThumbnail: isSet(object.receiverThumbnail) ? photo_1.Photo.fromJSON(object.receiverThumbnail) : undefined,
            courseCover: isSet(object.courseCover) ? photo_1.Photo.fromJSON(object.courseCover) : undefined,
            timestampMs: isSet(object.timestampMs) ? Number(object.timestampMs) : 0,
            readByReceiver: isSet(object.readByReceiver) ? Boolean(object.readByReceiver) : false,
            text: isSet(object.text) ? String(object.text) : undefined,
            image: isSet(object.image) ? photo_1.Photo.fromJSON(object.image) : undefined,
            video: isSet(object.video) ? photo_1.Photo.fromJSON(object.video) : undefined,
            action: isSet(object.action) ? message_ActionTypeFromJSON(object.action) : undefined,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.courseDiscussionId !== "") {
            obj.courseDiscussionId = message.courseDiscussionId;
        }
        if (message.senderId !== "") {
            obj.senderId = message.senderId;
        }
        if (message.senderName !== "") {
            obj.senderName = message.senderName;
        }
        if (message.senderThumbnail !== undefined) {
            obj.senderThumbnail = photo_1.Photo.toJSON(message.senderThumbnail);
        }
        if (message.receiverId !== "") {
            obj.receiverId = message.receiverId;
        }
        if (message.receiverName !== "") {
            obj.receiverName = message.receiverName;
        }
        if (message.receiverThumbnail !== undefined) {
            obj.receiverThumbnail = photo_1.Photo.toJSON(message.receiverThumbnail);
        }
        if (message.courseCover !== undefined) {
            obj.courseCover = photo_1.Photo.toJSON(message.courseCover);
        }
        if (message.timestampMs !== 0) {
            obj.timestampMs = Math.round(message.timestampMs);
        }
        if (message.readByReceiver === true) {
            obj.readByReceiver = message.readByReceiver;
        }
        if (message.text !== undefined) {
            obj.text = message.text;
        }
        if (message.image !== undefined) {
            obj.image = photo_1.Photo.toJSON(message.image);
        }
        if (message.video !== undefined) {
            obj.video = photo_1.Photo.toJSON(message.video);
        }
        if (message.action !== undefined) {
            obj.action = message_ActionTypeToJSON(message.action);
        }
        return obj;
    },
    create: function (base) {
        return exports.Message.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var message = createBaseMessage();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.courseDiscussionId = (_b = object.courseDiscussionId) !== null && _b !== void 0 ? _b : "";
        message.senderId = (_c = object.senderId) !== null && _c !== void 0 ? _c : "";
        message.senderName = (_d = object.senderName) !== null && _d !== void 0 ? _d : "";
        message.senderThumbnail = (object.senderThumbnail !== undefined && object.senderThumbnail !== null)
            ? photo_1.Photo.fromPartial(object.senderThumbnail)
            : undefined;
        message.receiverId = (_e = object.receiverId) !== null && _e !== void 0 ? _e : "";
        message.receiverName = (_f = object.receiverName) !== null && _f !== void 0 ? _f : "";
        message.receiverThumbnail = (object.receiverThumbnail !== undefined && object.receiverThumbnail !== null)
            ? photo_1.Photo.fromPartial(object.receiverThumbnail)
            : undefined;
        message.courseCover = (object.courseCover !== undefined && object.courseCover !== null)
            ? photo_1.Photo.fromPartial(object.courseCover)
            : undefined;
        message.timestampMs = (_g = object.timestampMs) !== null && _g !== void 0 ? _g : 0;
        message.readByReceiver = (_h = object.readByReceiver) !== null && _h !== void 0 ? _h : false;
        message.text = (_j = object.text) !== null && _j !== void 0 ? _j : undefined;
        message.image = (object.image !== undefined && object.image !== null) ? photo_1.Photo.fromPartial(object.image) : undefined;
        message.video = (object.video !== undefined && object.video !== null) ? photo_1.Photo.fromPartial(object.video) : undefined;
        message.action = (_k = object.action) !== null && _k !== void 0 ? _k : undefined;
        return message;
    },
};
var tsProtoGlobalThis = (function () {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();
function longToNumber(long) {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
