import BaseCalendar from './BaseCalendar';
import BaseClassItem from './BaseClassItem.vue';
import ClassIntroduce from './ClassIntroduce/index.vue';

import BaseButton from './Widgets/BaseButton.vue';
import BaseAvatar from './Widgets/BaseAvatar.vue';
import BaseImage from './Widgets/BaseImage.vue';

const components = {
  BaseCalendar,
  BaseClassItem,
  ClassIntroduce,
  BaseButton,
  BaseAvatar,
  BaseImage,
};
export default {
  install(app) {
    Object.keys(components).forEach((key) => {
      app.component(key, components[key]);
    });
  },
};
