<template>
  <div class="base-button">
    <el-button :class="`base-button ${type}`" :loading="isLoading" :style="style">{{
      title
    }}</el-button>
  </div>
</template>

<script setup>
import { computed } from 'vue';

/************************************************ 初始化数据 ************************************************/
const props = defineProps({
  title: String,
  isLoading: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'fill',
  },
  size: {
    type: Object,
    default: () => ({
      width: 300,
      height: 50,
    }),
  },
});

const style = computed(() => {
  let width = props.size.width || 300;
  let height = props.size.height || 50;

  return `width:${width}px; height:${height}px; border-radius: ${height / 2}px`;
});
/************************************************  生命周期  ************************************************/
/************************************************  数据请求  ************************************************/
/************************************************  点击事件  ************************************************/
/************************************************  回调事件  ************************************************/
/************************************************  辅助方法  ************************************************/
</script>
<style lang="scss" scoped>
.base-button {
  font-size: 18px;
  font-weight: 500;

  &.fill {
    background: var(--foundation-orange-orange-500, #ff9e2c);
    color: #fff;
  }

  &.linear {
    color: var(--foundation-orange-orange-500, #ff9e2c);
    border: 1px solid var(--foundation-orange-orange-500, #ff9e2c);
  }
}
</style>
