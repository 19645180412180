import { useI18n } from "vue-i18n";
import store from "@/store";

function getFormattedTimeFromTimestampMs(timestampMs, timezone, withDay = false) {
  const options = {
    timeZone: timezone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  };

  if (withDay) {
    options.month = '2-digit';
    options.day = '2-digit';
  }

  const date = new Date(timestampMs).toLocaleString('en-US', options);
  return date;
}

function computedAvailability(startTime) {
  const array = [];
  const today = new Date();
  // for (let i = 0; i < 7; i++) {
  //   let dayArray = []
  //   const date = new Date(today);
  //   date.setDate(today.getDate() + i);
  //   for (let i = startTime; i < 22; i++) {
  //     let item = {
  //       key: date + i,
  //       date: date,
  //       day: date.format('dd'),
  //       week: date.format('ddd'),
  //       time: i,
  //       select: false
  //     }
  //     if ((i * j + j + i) % 3 == 0) {
  //       item.select = true
  //       item.selectedClass = 'Math'
  //     }
  //     dayArray.push(item)
  //   }
  //   array.push({
  //     key: date.format('dd'),
  //     days: dayArray
  //   })
  // }

  for (let i = startTime; i < 22; i++) {
    let rows = [];
    for (let j = 0; j < 7; j++) {
      const date = new Date(today);
      date.setDate(today.getDate() + j);
      let item = {
        key: date + i,
        date: date,
        day: date.format('dd'),
        week: date.format('ddd'),
        time: i,
        select: false,
      };

      if ((i * j + j + i) % 3 == 0) {
        item.select = true;
        item.selectedClass = 'Math';
      }
      rows.push(item);
    }
    array.push({
      key: i + ':00',
      rows: rows,
    });
  }
  return array;
}

function handleAvailabilityList(startTime, endTime = 22) {
  const dateList = getRowHeaders();
  dateList.forEach((item) => {
    let times = [];
    for (let j = startTime; j < endTime; j++) {
      const calenderTime = new Date(item.timestamp).setHours(j, 0, 0, 0);
      times.push({
        day: item.day,
        time: j,
        timeStr: j + ':00',
        selected: true,
        timestamp: calenderTime,
      });
    }
    item['times'] = times;
  });
  return dateList;
}

function getRowHeaders(pastDays = 0) {
  const headers = [];
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  let pastSunday = pastDays - today.getDay();

  for (let i = pastSunday; i < 7 + pastSunday; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    headers.push({
      value: date.getDay() ,
      day: date.format('dd'),
      week: date.format('ddd'),
      timestamp: getBeijingTimestamp(date.getFullYear(), date.getMonth(), date.getDate()),
    });
  }
  return headers;
}
export const getOffset = (timeZone = "UTC", date = new Date()) => {
  const localDate = new Date(date.toLocaleString("en-US"));
  const tzDate = new Date(date.toLocaleString("en-US", {timeZone}));
  return (tzDate.getTime() - localDate.getTime());
};

const timezoneOffset = () => {
  const state = store.state
  if (state.user && !state.user.isTeacherAdmin && state.user.city && state.user.city.timezone) {
    return getOffset(state.user.city.timezone);
  }
  return getOffset('Asia/Shanghai')
}
const MILLIES_IN_A_WEEK = 24 * 7 * 60 * 60 * 1000;
function getBeijingTimestamp(year, month, day, hour = 0, minute = 0, second = 0) {
  // Create a Date object for the given date and time
  const inputDate = new Date(year, month, day, hour, minute, second);
  const tzOffset = timezoneOffset();
  return inputDate.getTime() - tzOffset;
}


function getColumnHeaders(startTime, endTime = 22) {
  const headers = [];
  for (let i = startTime; i < endTime; i++) {
    headers.push({
      time: i,
      timeStr: i + ':00',
    });
  }
  return headers;
}

function getTeacherColumnHeaders(startTime) {
  const headers = [];
  for (let i = startTime; i < 19; i++) {
    headers.push({
      time: i,
      timeStr: i + ':00',
    });
  }
  return headers;
}

function getMonths() {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months;
}

function getYears() {
  const start = 2000;
  const end = new Date().getFullYear();

  const years = [];
  for (let i = start; i <= end; i++) {
    years.push(i);
  }
  return years.reverse();
}

function getCourseTypeUntranslatedString(courseType) {
  switch (courseType) {
    case 1:
      return "classDetail.oneToOne";
    case 2:
      return "classDetail.oneToMany";
    case 3:
      return "classDetail.videoClass";
    case 4:
      return "classDetail.aiClass";
    default:
      return "classDetail.unknown";
  }
  return "classDetail.unknown";
}

function generateUUID() {
  const array = new Uint32Array(4);
  window.crypto.getRandomValues(array);
  return array.join('-');
}



export {
  getFormattedTimeFromTimestampMs,
  getCourseTypeUntranslatedString,
  computedAvailability,
  handleAvailabilityList,
  getRowHeaders,
  getColumnHeaders,
  getYears,
  getMonths,
  getTeacherColumnHeaders,
  generateUUID,
  MILLIES_IN_A_WEEK
};
