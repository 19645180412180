<!--  -->
<template>
  <div class="calendar-container boxV">
    <span v-if="showMonth" class="month-text mb-20">{{ currentMonth }}</span>
    <div class="boxH cr-center">
      <img class="past-future-button" v-if="props.adjustable" @click="pastClassOnClick" :src="iconPath.iconArrowLeftOrange" width="25" height="25"/>

      <div class="calendar-container boxV">
        <div class="calendar-top boxH">
          <div :class="`calendar-item ${size}`"></div>
          <div
            :class="`calendar-item ${size} boxV cr-center`"
            v-for="item in tableHeaders"
            :key="item.date"
          >
            <div class="fw-600">{{ item.week }}</div>
            <div class="mt-10" v-if="!onlyWeek">{{ item.day }}</div>
          </div>
        </div>
        <div class="calendar-center">
          <div class="choose-center boxH cr-center jc-between" v-if="showSchoolEnd">
            <div class="line"></div>
            <div class="title">School end</div>
            <div class="line"></div>
          </div>
          <div class="space" v-if="!showSchoolEnd"></div>
        </div>
        <div class="calendar-bottom boxH">
          <!-- column header -->
          <div class="boxV">
            <div
              :class="`calendar-item ${size} class-left-item`"
              v-for="date in columnHeaders"
              :key="date.time"
            >
              {{ date.timeStr }}
            </div>
          </div>
          <!-- content -->
          <div class="content boxH">
            <slot name="course-content"></slot>
            <div
              class="content-column boxV"
              v-for="date in classCalendars"
              :key="date.day"
            >
              <div
                :class="`calendar-item ${size}`"
                v-for="item in date.times"
                :key="item.time"
                @click="selectCalendarItem(item)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <img class="past-future-button" v-if="props.adjustable" @click="futureClassOnClick" :src="iconPath.iconArrowRightOrange" width="25" height="25"/>
    </div>
  </div>
  

</template>

<script setup>
import {
  getColumnHeaders,
  getRowHeaders,
  handleAvailabilityList,
} from '@/utils';
import iconPath from '@/assets/icons';
import { computed, ref, onMounted } from 'vue';
import { MILLIES_IN_A_WEEK } from "@/utils/util";

/************************************************ 初始化数据 ************************************************/
const props = defineProps({
  onlyWeek: Boolean,
  showMonth: Boolean,
  adjustable: Boolean,
  startHour: Number,
  endHour: Number,
  showSchoolEnd: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'larage',
  },
  isDisplayNextWeek: Boolean,
});

const emit = defineEmits(['setCourseStartTimestampMs', 'selectCalendarItem']);

const startClass = ref(0);
const startTimestampMs = ref();
const tableHeaders = ref(getRowHeaders(startClass.value));
const classCalendars = computed(() => {
  return handleAvailabilityList(props.startHour, props.endHour);
});

const columnHeaders = computed(() => {
  return getColumnHeaders(props.startHour, props.endHour);
});

const currentMonth = computed(() => {
  if(startTimestampMs.value){
    const startDate = new Date(startTimestampMs.value);
    const endDate = new Date(startTimestampMs.value + MILLIES_IN_A_WEEK);

    if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
        return startDate.toLocaleString('en-US', { month: 'long' });
    } 

    return `${startDate.toLocaleString('en-US', { month: 'long' })} 
          - ${endDate.toLocaleString('en-US', { month: 'long' })}`;
  }

  return null;
})

function extractedWeekHeaderAndStartTimestamp(pastDays = 0) {
  const dateList = getRowHeaders(pastDays);
  console.log("date list: ", dateList)
  startTimestampMs.value = dateList[0].timestamp;

  tableHeaders.value = dateList;

  emit('setCourseStartTimestampMs', dateList[0].timestamp);
}

/************************************************  生命周期  ************************************************/
onMounted(() => {
  if (props.isDisplayNextWeek) {
    futureClassOnClick();
  } else {
    extractedWeekHeaderAndStartTimestamp(0);
  }
})
/************************************************  点击事件  ************************************************/
function selectCalendarItem(item) {
  emit('selectCalendarItem', item.timestamp);
}

function futureClassOnClick() {
  startClass.value += 7;
  extractedWeekHeaderAndStartTimestamp(startClass.value);
}

function pastClassOnClick() {
  startClass.value -= 7;
  extractedWeekHeaderAndStartTimestamp(startClass.value);
}

</script>
<style lang="scss" scoped>
.calendar-container {

  .month-text {
    color: var(--foundation-grey-grey-500, #2c2e30);
    font-family: Poppins;
    font-size: 1.75rem;
    font-weight: 600;
  }
  .past-future-button {
    cursor: pointer;
  }
  .past-future-button:hover {
    opacity: 0.5;
  }
  .calendar-center {
    .choose-center {
      padding-top: 1.88rem;
      padding-bottom: 1.88rem;
      // border-top: 1px solid #EAEAEA;

      .line {
        width: 14.5625rem;
        height: 1px;
        background-color: #eaeaea;
      }

      .title {
        height: 1.25rem;
        color: var(--foundation-grey-grey-500, #2c2e30);
        font-size: 1.125rem;
        font-weight: 500;
      }
    }
    .space {
      height: 30px;
    }
  }

  .calendar-bottom {
    border-top: 1px solid #eaeaea;

    .content {
      position: relative;
    }
  }

  .calendar-item {
    box-sizing: border-box;
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    font-weight: 500;

    &.small {
      width: 5rem;
      height: 5.4rem;
      font-size: 1.125rem;
    }

    &.larage {
      width: 100px;
      height: 80px;
      font-size: 16px;
    }
  }

  .fw-600 {
    font-weight: 600;
  }
}

.class-left-item {
  text-align: center;
}
</style>
