"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.City = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
exports.protobufPackage = "com.afterschool.hero";
function createBaseCity() {
    return { formattedName: "", zipCodes: [], timezone: "", population: 0 };
}
exports.City = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.formattedName !== "") {
            writer.uint32(10).string(message.formattedName);
        }
        for (var _i = 0, _a = message.zipCodes; _i < _a.length; _i++) {
            var v = _a[_i];
            writer.uint32(58).string(v);
        }
        if (message.timezone !== "") {
            writer.uint32(66).string(message.timezone);
        }
        if (message.population !== 0) {
            writer.uint32(72).int32(message.population);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseCity();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.formattedName = reader.string();
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.zipCodes.push(reader.string());
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.timezone = reader.string();
                    continue;
                case 9:
                    if (tag !== 72) {
                        break;
                    }
                    message.population = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            formattedName: isSet(object.formattedName) ? String(object.formattedName) : "",
            zipCodes: Array.isArray(object === null || object === void 0 ? void 0 : object.zipCodes) ? object.zipCodes.map(function (e) { return String(e); }) : [],
            timezone: isSet(object.timezone) ? String(object.timezone) : "",
            population: isSet(object.population) ? Number(object.population) : 0,
        };
    },
    toJSON: function (message) {
        var _a;
        var obj = {};
        if (message.formattedName !== "") {
            obj.formattedName = message.formattedName;
        }
        if ((_a = message.zipCodes) === null || _a === void 0 ? void 0 : _a.length) {
            obj.zipCodes = message.zipCodes;
        }
        if (message.timezone !== "") {
            obj.timezone = message.timezone;
        }
        if (message.population !== 0) {
            obj.population = Math.round(message.population);
        }
        return obj;
    },
    create: function (base) {
        return exports.City.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d;
        var message = createBaseCity();
        message.formattedName = (_a = object.formattedName) !== null && _a !== void 0 ? _a : "";
        message.zipCodes = ((_b = object.zipCodes) === null || _b === void 0 ? void 0 : _b.map(function (e) { return e; })) || [];
        message.timezone = (_c = object.timezone) !== null && _c !== void 0 ? _c : "";
        message.population = (_d = object.population) !== null && _d !== void 0 ? _d : 0;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
