"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MagicCalendar_InterestToSessionsEntry = exports.MagicCalendar = exports.MatchingSessions = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
var weekly_session_1 = require("./weekly_session");
exports.protobufPackage = "com.afterschool.hero";
function createBaseMatchingSessions() {
    return { recommended: undefined, subs: [] };
}
exports.MatchingSessions = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.recommended !== undefined) {
            weekly_session_1.WeeklySession.encode(message.recommended, writer.uint32(10).fork()).ldelim();
        }
        for (var _i = 0, _a = message.subs; _i < _a.length; _i++) {
            var v = _a[_i];
            weekly_session_1.WeeklySession.encode(v, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseMatchingSessions();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.recommended = weekly_session_1.WeeklySession.decode(reader, reader.uint32());
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.subs.push(weekly_session_1.WeeklySession.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            recommended: isSet(object.recommended) ? weekly_session_1.WeeklySession.fromJSON(object.recommended) : undefined,
            subs: Array.isArray(object === null || object === void 0 ? void 0 : object.subs) ? object.subs.map(function (e) { return weekly_session_1.WeeklySession.fromJSON(e); }) : [],
        };
    },
    toJSON: function (message) {
        var _a;
        var obj = {};
        if (message.recommended !== undefined) {
            obj.recommended = weekly_session_1.WeeklySession.toJSON(message.recommended);
        }
        if ((_a = message.subs) === null || _a === void 0 ? void 0 : _a.length) {
            obj.subs = message.subs.map(function (e) { return weekly_session_1.WeeklySession.toJSON(e); });
        }
        return obj;
    },
    create: function (base) {
        return exports.MatchingSessions.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a;
        var message = createBaseMatchingSessions();
        message.recommended = (object.recommended !== undefined && object.recommended !== null)
            ? weekly_session_1.WeeklySession.fromPartial(object.recommended)
            : undefined;
        message.subs = ((_a = object.subs) === null || _a === void 0 ? void 0 : _a.map(function (e) { return weekly_session_1.WeeklySession.fromPartial(e); })) || [];
        return message;
    },
};
function createBaseMagicCalendar() {
    return { studentId: "", timezone: "", interestToSessions: {} };
}
exports.MagicCalendar = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.studentId !== "") {
            writer.uint32(10).string(message.studentId);
        }
        if (message.timezone !== "") {
            writer.uint32(26).string(message.timezone);
        }
        Object.entries(message.interestToSessions).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            exports.MagicCalendar_InterestToSessionsEntry.encode({ key: key, value: value }, writer.uint32(18).fork()).ldelim();
        });
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseMagicCalendar();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.studentId = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.timezone = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    var entry2 = exports.MagicCalendar_InterestToSessionsEntry.decode(reader, reader.uint32());
                    if (entry2.value !== undefined) {
                        message.interestToSessions[entry2.key] = entry2.value;
                    }
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            studentId: isSet(object.studentId) ? String(object.studentId) : "",
            timezone: isSet(object.timezone) ? String(object.timezone) : "",
            interestToSessions: isObject(object.interestToSessions)
                ? Object.entries(object.interestToSessions).reduce(function (acc, _a) {
                    var key = _a[0], value = _a[1];
                    acc[key] = exports.MatchingSessions.fromJSON(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.studentId !== "") {
            obj.studentId = message.studentId;
        }
        if (message.timezone !== "") {
            obj.timezone = message.timezone;
        }
        if (message.interestToSessions) {
            var entries = Object.entries(message.interestToSessions);
            if (entries.length > 0) {
                obj.interestToSessions = {};
                entries.forEach(function (_a) {
                    var k = _a[0], v = _a[1];
                    obj.interestToSessions[k] = exports.MatchingSessions.toJSON(v);
                });
            }
        }
        return obj;
    },
    create: function (base) {
        return exports.MagicCalendar.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c;
        var message = createBaseMagicCalendar();
        message.studentId = (_a = object.studentId) !== null && _a !== void 0 ? _a : "";
        message.timezone = (_b = object.timezone) !== null && _b !== void 0 ? _b : "";
        message.interestToSessions = Object.entries((_c = object.interestToSessions) !== null && _c !== void 0 ? _c : {}).reduce(function (acc, _a) {
            var key = _a[0], value = _a[1];
            if (value !== undefined) {
                acc[key] = exports.MatchingSessions.fromPartial(value);
            }
            return acc;
        }, {});
        return message;
    },
};
function createBaseMagicCalendar_InterestToSessionsEntry() {
    return { key: "", value: undefined };
}
exports.MagicCalendar_InterestToSessionsEntry = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            exports.MatchingSessions.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseMagicCalendar_InterestToSessionsEntry();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.key = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.value = exports.MatchingSessions.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? exports.MatchingSessions.fromJSON(object.value) : undefined,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.key !== "") {
            obj.key = message.key;
        }
        if (message.value !== undefined) {
            obj.value = exports.MatchingSessions.toJSON(message.value);
        }
        return obj;
    },
    create: function (base) {
        return exports.MagicCalendar_InterestToSessionsEntry.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a;
        var message = createBaseMagicCalendar_InterestToSessionsEntry();
        message.key = (_a = object.key) !== null && _a !== void 0 ? _a : "";
        message.value = (object.value !== undefined && object.value !== null)
            ? exports.MatchingSessions.fromPartial(object.value)
            : undefined;
        return message;
    },
};
function isObject(value) {
    return typeof value === "object" && value !== null;
}
function isSet(value) {
    return value !== null && value !== undefined;
}
