"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Photo = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
exports.protobufPackage = "com.afterschool.hero";
function createBasePhoto() {
    return { id: "", url: "", description: "", isVideo: false };
}
exports.Photo = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.url !== "") {
            writer.uint32(18).string(message.url);
        }
        if (message.description !== "") {
            writer.uint32(26).string(message.description);
        }
        if (message.isVideo === true) {
            writer.uint32(32).bool(message.isVideo);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBasePhoto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.url = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.description = reader.string();
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }
                    message.isVideo = reader.bool();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            url: isSet(object.url) ? String(object.url) : "",
            description: isSet(object.description) ? String(object.description) : "",
            isVideo: isSet(object.isVideo) ? Boolean(object.isVideo) : false,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.url !== "") {
            obj.url = message.url;
        }
        if (message.description !== "") {
            obj.description = message.description;
        }
        if (message.isVideo === true) {
            obj.isVideo = message.isVideo;
        }
        return obj;
    },
    create: function (base) {
        return exports.Photo.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d;
        var message = createBasePhoto();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.url = (_b = object.url) !== null && _b !== void 0 ? _b : "";
        message.description = (_c = object.description) !== null && _c !== void 0 ? _c : "";
        message.isVideo = (_d = object.isVideo) !== null && _d !== void 0 ? _d : false;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
