"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Teacher = exports.protobufPackage = void 0;
/* eslint-disable */
var _m0 = require("protobufjs/minimal");
var photo_1 = require("./photo");
exports.protobufPackage = "com.afterschool.hero";
function createBaseTeacher() {
    return { id: "", teacherAdminId: "", teacherName: "", thumbnail: undefined };
}
exports.Teacher = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.teacherAdminId !== "") {
            writer.uint32(18).string(message.teacherAdminId);
        }
        if (message.teacherName !== "") {
            writer.uint32(26).string(message.teacherName);
        }
        if (message.thumbnail !== undefined) {
            photo_1.Photo.encode(message.thumbnail, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseTeacher();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.teacherAdminId = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.teacherName = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.thumbnail = photo_1.Photo.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            teacherAdminId: isSet(object.teacherAdminId) ? String(object.teacherAdminId) : "",
            teacherName: isSet(object.teacherName) ? String(object.teacherName) : "",
            thumbnail: isSet(object.thumbnail) ? photo_1.Photo.fromJSON(object.thumbnail) : undefined,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.teacherAdminId !== "") {
            obj.teacherAdminId = message.teacherAdminId;
        }
        if (message.teacherName !== "") {
            obj.teacherName = message.teacherName;
        }
        if (message.thumbnail !== undefined) {
            obj.thumbnail = photo_1.Photo.toJSON(message.thumbnail);
        }
        return obj;
    },
    create: function (base) {
        return exports.Teacher.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c;
        var message = createBaseTeacher();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.teacherAdminId = (_b = object.teacherAdminId) !== null && _b !== void 0 ? _b : "";
        message.teacherName = (_c = object.teacherName) !== null && _c !== void 0 ? _c : "";
        message.thumbnail = (object.thumbnail !== undefined && object.thumbnail !== null)
            ? photo_1.Photo.fromPartial(object.thumbnail)
            : undefined;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
