import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Layout from '../layout';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    children: [
      { path: '/', name: 'home', component: () => import('../views/Home.vue') },
    ],
    meta: { requiresAuth: false },
  },
  {
    path: '/#',
    name: 'home1',
    component: Layout,
    children: [
      {
        path: '/chat-online/:courseDiscussion',
        name: 'chat-online-with-course-id',
        component: () => import('../views/ChatOnline'),
      },
      {
        path: '/chat-online',
        name: 'chat-online',
        component: () => import('../views/ChatOnline'),
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard'),
      },
      {
        path: '/past-classes',
        name: 'past-classes',
        component: () => import('../views/PastClasses'),
      },
      {
        path: '/class-calendar',
        name: 'class-calendar',
        component: () => import('../views/ClassCalendar'),
      },
      {
        path: '/teacher-calendar',
        name: 'teacher-calendar',
        component: () => import('../views/TeacherCalendar'),
      },
      {
        path: '/add-new-course/:id?',
        name: 'add-new-course',
        component: () => import('../views/AddNewCourse'),
      },
      {
        path: '/student-detail',
        name: 'student-detail',
        component: () => import('../views/StudentDetail'),
      },
      {
        path: '/support-entry',
        name: 'support-entry',
        component: () => import('../views/SupportEntry'),
      },
      {
        path: '/student-settings',
        name: 'student-settings',
        component: () => import('../views/StudentSettings'),
      },
      {
        path: '/reschedule',
        name: 'reschedule',
        component: () => import('../views/Reschedule'),
      },
      {
        path: '/fill-questionnaire',
        name: 'questionnaire',
        component: () => import('../views/FillQuestionnaire'),
      },
      {
        path: '/reviews',
        name: 'reviews',
        component: () => import('../views/Reviews'),
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import('../views/SignIn'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/Terms'),
  },
  {
    path: '/perfect-member',
    name: 'perfect-member',
    component: () => import('../views/PerfectMember'),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
