<!--  -->
<template>
  <div class="download">
    <div class="download-title">{{ download.title }}</div>
    <div class="download-content" v-html="download.content"></div>
    <el-button class="download-btn" @click="downloadOnClick">Download software</el-button>
    <el-button class="question-btn" @click="clickedQuestion">Can’t make it ?</el-button>
  </div>
</template>

<script setup>

import { ref } from 'vue'
/************************************************ 初始化数据 ************************************************/
const emit = defineEmits(['submitQuestion'])
const props = defineProps({
  download: Object
})
/************************************************  生命周期  ************************************************/
/************************************************  数据请求  ************************************************/
/************************************************  点击事件  ************************************************/
function downloadOnClick() {
  window.open(props.download.downloadUrl);
}

function clickedQuestion() {
  emit('submitQuestion')
}
/************************************************  回调事件  ************************************************/
/************************************************  辅助方法  ************************************************/
</script>
<style lang="scss" scoped>
.download {
  width: 25rem;
  padding: 1.25rem;
  border-radius: 0.625rem;
  border: 1px solid var(--foundation-grey-grey-100, #bebebf);
  color: var(--foundation-grey-grey-500, #2c2e30);
  font-size: 1rem;
  font-weight: 400;

  .download-title {
    color: var(--foundation-grey-grey-500, #2c2e30);
    font-size: 1.3125rem;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }

  .download-title,
  .download-content,
  .download-btn {
    margin-bottom: 1.25rem;
  }

  .download-btn,
  .question-btn {
    width: 100%;
    height: 3.125rem;
    border-radius: 3.125rem;
    font-size: 1.125rem;
    font-weight: 500;
  }

  .download-btn {
    color: #fff;
    background: var(--foundation-orange-orange-500, #ff9e2c);
  }

  .question-btn {
    color: var(--foundation-orange-orange-500, #ff9e2c);
    border: 1px solid var(--foundation-orange-orange-500, #ff9e2c);
  }

  ::v-deep(.el-button) {
    margin-left: 0;
  }
}
</style>