<!--  -->
<template>
  <div class="gallery-content boxV">
    <div class="gallery-text">Gallery</div>
    <div class="gallery-list">
      <div v-for="photo in displayedGallery" :key="photo.id">
        <div class="gallery-image-container">
          <template  v-if="!photo.isVideo">
            <BaseImage @click="imageOnClick(photo)" class="gallery-image" :photo="photo"></BaseImage>
          </template>
          <template v-else-if="photo.isVideo">
            <div class="video-view" @click="openVideoInModel(photo)">
              <video style="width: 100% !important;">
                <source :src="photo.url" type="video/mp4">
              </video>
              <div class="play-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                  <g fill="none" fill-rule="evenodd">
                    <circle cx="20" cy="20" r="20" fill="#FF9E2C" fill-rule="nonzero"/>
                    <path fill="#FFF" d="M15.5 13.5v13l10-6.5z"/>
                  </g>
                </svg>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="displayedGallery.length < galleryPhotos.length">
        <span @click="loadMoreImages" class="load-more-button">Show more</span>
      </div>
    </div>
    <el-dialog :append-to-body="true" v-model="isVisibleVideoModal" width="70%" center modal-class="video-player-class" :before-close="handleVideoCloseModal">
      <template #header>
        <div class="dialog-title">Video</div>
      </template>
      <template #default>
        <video ref="videoPlayer" :src="videoUrl" controls style="width: 100% !important;"></video>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>

import { computed, ref, onMounted, onActivated, onUpdated, onBeforeUpdate } from 'vue'
/************************************************ 初始化数据 ************************************************/
const props = defineProps({
  galleryPhotos: Object,
})

const emits = defineEmits(['setGalleryVisible']);

const isVisibleVideoModal = ref(false)
const videoUrl = ref(null)
const videoPlayer = ref(null)

const allImagesLoaded = ref(false)
const displayedGallery = computed(() => {
  if (!props.galleryPhotos) return []
  let count = allImagesLoaded.value ? props.galleryPhotos.length : 3
  let images = props.galleryPhotos.slice(0, count)
  return images
})

function imageOnClick(photo) {
  emits('setGalleryVisible', photo);
}

function loadMoreImages() {
  allImagesLoaded.value = true
}

const openVideoInModel = (photo) => {
  isVisibleVideoModal.value = true
  videoUrl.value = photo.url
}

const handleVideoCloseModal = () => {
  videoUrl.value = null
  if (videoPlayer.value) {
    videoPlayer.value.pause()
  }
  isVisibleVideoModal.value = false
}

/************************************************  生命周期  ************************************************/
/************************************************  数据请求  ************************************************/
/************************************************  点击事件  ************************************************/
/************************************************  回调事件  ************************************************/
/************************************************  辅助方法  ************************************************/
</script>
<style lang="scss" scoped>
.gallery-content {
  width: 25rem;
  padding: 1.25rem;
  border-radius: 0.625rem;
  border: 1px solid var(--foundation-grey-grey-100, #bebebf);
  color: var(--foundation-grey-grey-500, #2c2e30);
  font-size: 1rem;
  font-weight: 400;

  .gallery-text {
    color: var(--foundation-grey-grey-500, #2c2e30);
    font-size: 1.3125rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .gallery-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .gallery-image-container {
      max-width: 100%;
      max-height: 10rem;
      overflow: hidden;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.625rem;
    }

    .gallery-image {
      width: 100%;
      height: auto;
      display: block;
    }

    .load-more-button {
      cursor: pointer;
      font-size: 1.3125rem;
      font-weight: 500;
      color: var(--foundation-orange-orange-500, #ff9e2c);
      text-decoration: underline;
    }

  }

  .video-view {
    position: relative;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    svg {
      g {
        circle {
          fill: var(--foundation-orange-orange-500, #ff9e2c);
        }
        path {
          fill: #fff !important; 
        } 
      }
    }
  }
}
</style>