import { createI18n } from 'vue-i18n';

import enUS from './locales/en-US';
import zhCN from './locales/zh-CN';

const messages = {
  'en-US': enUS,
  'zh-CN': zhCN,
};

const i18n = createI18n({
  legacy: false,
  // locale: 'zh-CN',
  locale: 'en-US',
  fallbackLocale: 'en-US',
  messages,
});

export default i18n;
